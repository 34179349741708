import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { io, Socket } from 'socket.io-client';
import { Spinner } from '../components/spinner/Spinner';
import { WS_CONNECTION_URL } from '../constants/envConstants';
import { pageReload } from '../screens/utils/reloadUtil';
import styles from './allowOneSocketConnectionContext.module.css';

interface ContextValue {
  socket: Socket;
  connected: boolean;
  loadingSession: boolean;
}

interface Props {
  children: React.ReactNode;
}

const AllowOneSocketConnectionContext =
  React.createContext<ContextValue | null>(null);
const TAB_ID = 'tabId';

const handleRefresh = (event: React.MouseEvent<HTMLAnchorElement>) => {
  event.preventDefault();
  pageReload();
};

const refreshMessage = (
  <span>
    Click{' '}
    <a href="/" onClick={handleRefresh} data-testid="reloadPage">
      here
    </a>{' '}
    to refresh the page
  </span>
);

export const AllowOneSocketConnectionProvider = ({ children }: Props) => {
  const { respondentId } = useParams<{ respondentId: string }>();
  const { livePollSessionId } = useParams<{ livePollSessionId: string }>();
  const [connectionError, setConnectionError] = React.useState<
    Error | undefined
  >();
  const [connected, setConnected] = React.useState<boolean>(true);
  const [loadingSession, setLoadingSession] = React.useState<boolean>(true);

  const socketRef = React.useRef<Socket | null>(null);
  const processConnectedInAnotherTab = () => {
    const tabId = Date.now().toString();
    localStorage.setItem(TAB_ID, tabId);

    window.onstorage = (storageEvent: StorageEvent) => {
      const updatedLocalStorageKey = storageEvent.key;
      if (updatedLocalStorageKey === TAB_ID) {
        if (storageEvent.newValue != null) {
          // is connected in another tab, disconnect
          const socketDisconnectPromise = new Promise<void>(resolve => {
            socketRef.current?.on('disconnect', () => {
              resolve();
            });
          });
          socketRef.current?.disconnect();
          const timeoutPromise = new Promise<void>(resolve => {
            setTimeout(resolve, 5_000);
          });

          Promise.race([socketDisconnectPromise, timeoutPromise]).then(() => {
            window.location.replace('/enter-pin');
            localStorage.removeItem(TAB_ID);
          });
        }
      }
    };
  };

  React.useEffect(() => {
    // check if opened in another tab
    processConnectedInAnotherTab();

    return () => {
      // setConnected(false);
      socketRef.current?.disconnect();
      window.onstorage = null;
    };
  }, []);

  if (!socketRef.current) {
    const socket = io(WS_CONNECTION_URL, {
      path: '/api/respondent',
      auth: {
        respondentId,
        livePollSessionId,
      },
      withCredentials: true,
    });
    socketRef.current = socket;

    socket.on('connect', () => {
      // On connect/reconnect sync the LivePollSession again
      setConnected(true);
      setConnectionError(undefined);
      setLoadingSession(false);
    });

    socket.on('disconnect', reason => {
      console.log('socket disconnected: ', reason);
      setConnected(false);
    });

    socket.on('connect_error', error => {
      setConnectionError(error);
    });

    socket.on('close', () => {
      setLoadingSession(true);
      setConnected(false);
      socket.connect();
    });
  }

  const value = useMemo(
    () => ({
      socket: socketRef.current!,
      connected,
      loadingSession,
    }),
    [connected, loadingSession],
  );

  if (connectionError) {
    return (
      <>
        <div className={styles.socketDisconnectionMessage}>
          Oops, connection error ({connectionError.message}).
          {refreshMessage}
        </div>
      </>
    );
  }

  if (loadingSession) {
    return <Spinner message="Loading..." />;
  }

  return (
    <AllowOneSocketConnectionContext.Provider value={value}>
      {children}
    </AllowOneSocketConnectionContext.Provider>
  );
};

export const useAllowOneSocketConnection = () => {
  const context = React.useContext(AllowOneSocketConnectionContext);
  if (!context) {
    throw new Error(
      `useAllowOneSocketConnection must be used within AllowOneSocketConnectionContext`,
    );
  }

  return context;
};
