export const LeaderboardSvg = () => {
  const image = (
    <svg
      width="112px"
      height="89px"
      viewBox="0 0 112 89"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Illustration/leaderboard</title>
      <g
        id="Respondent-Flow-Mobile"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="R_11-Copy" transform="translate(-151.000000, -176.000000)">
          <g
            id="Illustration/leaderboard"
            transform="translate(127.000000, 166.000000)"
          >
            <polyline
              id="Path"
              stroke="currentColor"
              strokeWidth="3.2"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="25.6 90.1937477 25.6 83.8187477 33.6 77.4437477 57.6 77.4437477 57.6 83.8187477 57.6 93.3812477"
            ></polyline>
            <g id="Group-3" transform="translate(64.000000, 47.162498)">
              <polyline
                id="Path"
                stroke="currentColor"
                strokeWidth="3.2"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="0 39.84375 0 5.578125 6.4 0.796875 25.6 0.796875 32 5.578125 32 49.40625"
              ></polyline>
              <polygon
                id="Rectangle"
                fill="currentColor"
                points="8 -1.13242749e-14 24 -1.13242749e-14 32 6.375 0 6.375"
              ></polygon>
            </g>
            <polyline
              id="Path"
              stroke="currentColor"
              strokeWidth="3.2"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="102.4 90.1937477 102.4 71.0687477 102.4 64.6937477 128 64.6937477 134.4 71.0687477 134.4 83.8187477"
            ></polyline>
            <polygon
              id="Rectangle"
              fill="currentColor"
              points="33.6 77.4437477 57.6 77.4437477 57.6 84.6156227 24.8 84.6156227"
            ></polygon>
            <polygon
              id="Rectangle-Copy"
              fill="currentColor"
              transform="translate(118.800000, 68.279685) scale(-1, 1) translate(-118.800000, -68.279685) "
              points="109.6 64.6937477 135.2 64.6937477 135.2 71.8656227 102.4 71.8656227"
            ></polygon>
            <path
              d="M82.0255527,9.73916186 L87.2766511,20.2404221 L99.0908418,21.9253228 L90.5340687,30.1495815 L92.5382894,41.7144781 L82.0106277,36.2625021 L71.4706564,41.7311381 L73.4913896,30.1578199 L64.9479611,21.9472194 L76.7605461,20.2470893 L82.0255527,9.73916186 Z"
              id="Path"
              stroke="currentColor"
              strokeWidth="3.2"
              strokeLinejoin="round"
              transform="translate(82.017582, 26.068054) rotate(10.000000) translate(-82.017582, -26.068054) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );

  return image;
};
