import styles from './Incorrect.module.css';

interface Props {
  circleColor?: string;
}

export const IncorrectAnswer = ({ circleColor }: Props) => {
  return (
    <div className={styles.container} data-testid="incorrectAnswer">
      <div
        className={styles.circle}
        style={{ backgroundColor: circleColor }}
      ></div>
      <div className={styles.circleContent}>
        <span className={styles.innerCircle}></span>
        <span className={styles.line + ' ' + styles.lineBottomRight}></span>
        <span className={styles.line + ' ' + styles.lineBottomLeft}></span>
        <span className={styles.line + ' ' + styles.lineTopLeft}></span>
        <span className={styles.line + ' ' + styles.lineTopRight}></span>
      </div>
    </div>
  );
};
