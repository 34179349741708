import styles from './TextCard.module.scss';
import { SwipeQuestionOptionSide } from '../../../../../models/SwipeQuestionOptionSide.enum';
import { CardAnswerTextRow } from './CardAnswerTextRow';
import { forwardRef } from 'react';
import { getFontSizeForSwipeQuestionCard } from '../../../../utils/fontUtil';

interface Props {
  idx: number;
  cardTitle: string;
  cardSwipeDirection: SwipeQuestionOptionSide | null;
  leftText: string;
  rightText: string;
  gradientOverlayRef: React.RefObject<HTMLDivElement> | null;
  cardAnswerTextRowRef: React.RefObject<HTMLDivElement> | null;
}

export const TextCard = forwardRef<HTMLDivElement, Props>(
  (
    {
      idx,
      cardTitle,
      cardSwipeDirection,
      leftText,
      rightText,
      gradientOverlayRef,
      cardAnswerTextRowRef,
    },
    ref,
  ) => {
    const fontSizeWithPx = getFontSizeForSwipeQuestionCard(cardTitle);
    return (
      <>
        <CardAnswerTextRow
          idx={idx}
          isImageCard={false}
          cardSwipeDirection={cardSwipeDirection}
          leftText={leftText}
          rightText={rightText}
          cardAnswerTextRowRef={cardAnswerTextRowRef}
        />
        <span
          className={`${styles.cardText} ${
            fontSizeWithPx === '16px' ? styles.alignBottom : ''
          }`}
          style={{ fontSize: fontSizeWithPx }}
        >
          {cardTitle || 'Untitled'}
        </span>
        <div className={styles.swipeGradientOverlay} ref={gradientOverlayRef} />
      </>
    );
  },
);
