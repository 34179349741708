import { useState } from 'react';
import { useTakeLivePollState } from '../../../contexts/takeLivePollStateContext';
import { useInterval } from '../../../hooks/useInterval';
import styles from './SwipeQuestionResult.module.scss';
import { IncorrectAnswer } from '../../../components/animation/incorrectAnswer/IncorrectAnswer';
import { ISwipeQuestion } from '../../../models/SwipeQuestion';
import { QuestionFinishedLeaderboard } from './QuestionFinishedLeaderboard';
import { QuestionDifficultyPoints } from '../../../models/QuestionDifficultyPoints.enum';
import { CorrectInsideCircleSvg } from '../../../components/svgImages/correctInsideCircleSvg';
import { SpeedRankIconSvg } from '../../../components/svgImages/speedRankIconSvg';

export const SwipeQuestionResult = () => {
  const { serverState } = useTakeLivePollState();
  const { question, swipeQuestionResponse, leaderboard } = serverState;

  const [showLeaderboard, setShowLeaderboard] = useState<boolean>(false);
  useInterval(() => {
    if (leaderboard) {
      setShowLeaderboard(true);
    }
  }, 5000);

  const swipeQuestion = question as ISwipeQuestion;
  const correctSwipeCount = swipeQuestionResponse?.correctSwipeCount || 0;
  const allCardsScore = swipeQuestionResponse?.allCardsScore || 0;

  const answerCountDiv = (
    <div className={styles.answerDiv}>
      <span>
        {correctSwipeCount}/{swipeQuestion.cards.length} right answers
      </span>
    </div>
  );
  return (
    <>
      {!showLeaderboard && (
        <div>
          {correctSwipeCount === 0 ? (
            <>
              <div className={styles.incorrectAnswer}>
                <IncorrectAnswer circleColor="var(--main, $main-color)" />
              </div>
              {answerCountDiv}
            </>
          ) : (
            <>
              <div className={styles.correctIconScore}>
                <span className={styles.icon}>
                  <CorrectInsideCircleSvg />
                </span>
                <span>+{getCorrectnessScore(correctSwipeCount)}</span>
              </div>
              {answerCountDiv}
              <div className={styles.speedIconScore}>
                <span>+ {getSpeedScore(correctSwipeCount, allCardsScore)}</span>
                <div className={styles.speedText}>
                  <span className={styles.icon}>
                    <SpeedRankIconSvg />
                  </span>
                  <span>Speed</span>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {showLeaderboard && <QuestionFinishedLeaderboard />}
    </>
  );
};

const getCorrectnessScore = (correctSwipeCount: number) =>
  correctSwipeCount * QuestionDifficultyPoints.NORMAL;

const getSpeedScore = (correctSwipeCount: number, allCardsScore: number) => {
  const correctnessScore = getCorrectnessScore(correctSwipeCount);
  const speedScore = allCardsScore - correctnessScore;

  return speedScore > 0 ? speedScore : 0;
};
