import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import styles from './ActionButton.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  showLoader?: boolean | undefined;
}

export const ActionButton = ({
  children,
  onClick,
  showLoader,
  ...restProps
}: Props) => {
  const mounted = useRef(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const handleClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setIsDisabled(true);
    await onClick(event);
    if (mounted.current) {
      setIsDisabled(false);
    }
  };
  return (
    <button
      className={styles.defaultStyle}
      onClick={handleClick}
      disabled={isDisabled}
      {...restProps}
    >
      {showLoader && isDisabled ? (
        <FontAwesomeIcon
          icon={faSpinner}
          className={`fa-spin ${styles.spinner}`}
        />
      ) : (
        ''
      )}
      {children}
    </button>
  );
};
