import { QuestionDifficultyLevel } from '../../enums/question-difficulty-level.enum';
import easyFlagIcon from '../../images/question-difficulty-easy-flag.svg';
import normalFlagIcon from '../../images/question-difficulty-normal-flag.svg';
import hardFlagIcon from '../../images/question-difficulty-hard-flag.svg';
import styles from './LpDifficultyLevelIndicator.module.css';
import { CSSProperties } from 'react';

interface TranslatedText {
  easy: string;
  normal: string;
  hard: string;
}
interface Props {
  questionDifficultyLevel: QuestionDifficultyLevel;
  text: TranslatedText;
  difficultyLevelIndicatorStyle?: CSSProperties;
}

export const LpDifficultyLevelIndicator = ({
  questionDifficultyLevel,
  text: translatedText,
  difficultyLevelIndicatorStyle,
}: Props) => {
  const { icon, text } = getIconAndTextByDifficultyLevel(
    questionDifficultyLevel,
    translatedText,
  );

  return (
    <div className={styles.container}>
      <img src={icon} alt="difficulty level" className={styles.flagIcon} />
      <span style={difficultyLevelIndicatorStyle}>{text}</span>
    </div>
  );
};

const getIconAndTextByDifficultyLevel = (
  difficultyLevel: QuestionDifficultyLevel,
  translatedText: TranslatedText,
): { icon: string; text: string } => {
  switch (difficultyLevel) {
    case QuestionDifficultyLevel.EASY:
      return { icon: easyFlagIcon, text: translatedText.easy };
    case QuestionDifficultyLevel.NORMAL:
      return { icon: normalFlagIcon, text: translatedText.normal };
    case QuestionDifficultyLevel.HARD:
      return { icon: hardFlagIcon, text: translatedText.hard };
    default:
      throw new Error('Incorrect question difficulty level');
  }
};
