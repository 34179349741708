import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_URL } from '../../constants/envConstants';

export const callApi = (endpoint: string, config?: AxiosRequestConfig) => {
  return axios({
    url: `${API_URL}${endpoint}`,
    ...config,
    withCredentials: true,
  });
};

export const getApi = async (
  endpoint: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse> => {
  return callApi(endpoint, { method: 'GET', ...config });
};

export const postApi = async (
  endpoint: string,
  data: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse> => {
  return await callApi(endpoint, { method: 'POST', data, ...config });
};
