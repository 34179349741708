export const CorrectInsideCircleSvg = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44ZM13.9762 22.7238C13.0088 21.7578 11.4416 21.7584 10.4749 22.7251C9.50838 23.6916 9.50838 25.2586 10.4749 26.2251L17.4749 33.2251L33.2249 17.4751C34.1914 16.5086 34.1914 14.9416 33.2249 13.9751C32.2583 13.0085 30.6912 13.0083 29.7244 13.9746L17.4749 26.2172L13.9762 22.7238Z"
        fill="currentColor"
      />
    </svg>
  );
};
