import { useTakeLivePollState } from '../../../contexts/takeLivePollStateContext';
import { AdminQuestion } from '../../../models/AdminQuestion';
import { IAnswer } from '../../../models/Answer';
import { LivePollType } from '../../../models/LivePollType';
import styles from './QuestionResult.module.scss';
import { IChoiceQuestion } from '../../../models/ChoiceQuestion';
import React from 'react';
import { QuestionFinishedLeaderboard } from './QuestionFinishedLeaderboard';
import { useInterval } from '../../../hooks/useInterval';
import { AnswerSubmittedSvg } from '../../../components/svgImages/answerSubmittedSvg';
import { LoadImage } from '../../../components/load-image/LoadImage';
import { IQuestionDifficultySetting } from '../../../models/QuestionDifficultySetting.interafce';
import { QuestionDifficultyPoints } from '../../../models/QuestionDifficultyPoints.enum';
import easyFlagIcon from '../../../images/question-difficulty-easy-flag.svg';
import normalFlagIcon from '../../../images/question-difficulty-normal-flag.svg';
import hardFlagIcon from '../../../images/question-difficulty-hard-flag.svg';
import { Points } from '../../../components/animation/points/Points';
import { IncorrectAnswer } from '../../../components/animation/incorrectAnswer/IncorrectAnswer';
import { ChoiceQuestionResponse } from '../../../models/ChoiceQuestionResponse';
import { QuestionDifficultyLevel } from '@livepolls/ui-components/src/enums/question-difficulty-level.enum';

const getDifficultyLevelIndicatorFlag = (
  difficultyLevel: QuestionDifficultyLevel,
) => {
  switch (difficultyLevel) {
    case QuestionDifficultyLevel.EASY:
      return easyFlagIcon;
    case QuestionDifficultyLevel.NORMAL:
      return normalFlagIcon;
    case QuestionDifficultyLevel.HARD:
      return hardFlagIcon;
    default:
      throw new Error('Incorrect question difficulty level');
  }
};

const getScoreByDifficultyLevel = (
  difficultyLevel: QuestionDifficultyLevel,
  difficultySetting: IQuestionDifficultySetting,
) => {
  switch (difficultyLevel) {
    case QuestionDifficultyLevel.EASY:
      return difficultySetting.easy;
    case QuestionDifficultyLevel.NORMAL:
      return difficultySetting.normal;
    case QuestionDifficultyLevel.HARD:
      return difficultySetting.hard;
    default:
      throw new Error('Incorrect question difficulty level');
  }
};

const generateDefaultQuestionDifficultySettings =
  (): IQuestionDifficultySetting => {
    return {
      easy: QuestionDifficultyPoints.EASY,
      normal: QuestionDifficultyPoints.NORMAL,
      hard: QuestionDifficultyPoints.HARD,
    };
  };

const speedScore = (
  totalScore: number,
  difficultyLevel: QuestionDifficultyLevel,
  difficultySetting: IQuestionDifficultySetting,
) => {
  const scoreByDifficultyLevel = getScoreByDifficultyLevel(
    difficultyLevel,
    difficultySetting,
  );
  if (totalScore <= scoreByDifficultyLevel) {
    return 0;
  }

  return totalScore - scoreByDifficultyLevel;
};

const correctAnswerScore = (
  score: number,
  difficultyLevel: QuestionDifficultyLevel,
  difficultySetting: IQuestionDifficultySetting,
) => {
  if (score > 0) {
    return getScoreByDifficultyLevel(difficultyLevel, difficultySetting);
  }
  return 0;
};

const renderOrderNumber = (answers: IAnswer[], answer: IAnswer) => {
  const index = answers.findIndex((t: IAnswer) => t.id === answer.id);
  if (answers.length > 26) {
    return <span>{`${index + 1}) `}</span>;
  }
  return <span>{`${letterForIndex(index)}) `}</span>;
};

const letterForIndex = (index: number) => {
  return String.fromCharCode(97 + index);
};

export const QuestionResult = () => {
  const { serverState } = useTakeLivePollState();
  const { question, questionResponse, livePollSession, leaderboard } =
    serverState;
  const [showLeaderboard, setShowLeaderboard] = React.useState<boolean>(false);
  useInterval(() => {
    if (leaderboard) {
      setShowLeaderboard(true);
    }
  }, 5000);
  const choiceQuestionResponse = questionResponse as ChoiceQuestionResponse;

  if (!question) {
    throw new Error(`question cannot be undefined`);
  }
  let currentQuestion = question as IChoiceQuestion;

  if (!livePollSession) {
    throw new Error(`livePollSession cannot be undefined`);
  }

  let selectedAnswer =
    choiceQuestionResponse &&
    currentQuestion.answers.find(a => a.id === choiceQuestionResponse.answerId);

  const questionDifficultyLevel =
    livePollSession.setting?.enableQuestionDifficulty &&
    currentQuestion.difficultyLevel != null
      ? currentQuestion.difficultyLevel
      : QuestionDifficultyLevel.NORMAL;

  const questionDifficultySetting =
    livePollSession.setting?.enableQuestionDifficulty &&
    livePollSession.setting?.questionDifficultySetting
      ? livePollSession.setting?.questionDifficultySetting
      : generateDefaultQuestionDifficultySettings();

  const showDifficultyLevelIndicator =
    !!livePollSession.setting?.displayDifficultyLevels;

  if (livePollSession.livePollType === LivePollType.POLL) {
    if (!selectedAnswer) {
      return null;
    }

    return (
      <div className={styles.container + ' ' + styles.message}>
        <span className={styles.pollVoteImage}>{<AnswerSubmittedSvg />}</span>
        {selectedAnswer?.image?.url && (
          <div className={styles.selectedAnswerContainer}>
            <div className={styles.innerSelectedAnswerContainer}>
              <div className={styles.answerText}>{selectedAnswer.text}</div>
              {selectedAnswer.image?.url && (
                <LoadImage
                  imageUrl={selectedAnswer.image?.url}
                  imgAltText="answer"
                  isOption={true}
                />
              )}
            </div>
          </div>
        )}
        {!selectedAnswer.image?.url && (
          <div className={styles.answerDiv}>{selectedAnswer.text}</div>
        )}
      </div>
    );
  }

  const correct = !!choiceQuestionResponse && !!choiceQuestionResponse.correct;
  const renderResultAndScore = () => {
    if (!choiceQuestionResponse || !selectedAnswer) {
      return null;
    }

    if (!correct) {
      return (
        <div className={styles.resultIcon}>
          <div className={styles.incorrectAnswer}>
            <IncorrectAnswer />
          </div>
          {selectedAnswer.image?.url ? (
            <div className={styles.selectedAnswerContainer}>
              <div className={styles.innerSelectedAnswerContainer}>
                <div className={styles.answerText}>{selectedAnswer.text}</div>
                {selectedAnswer.image?.url && (
                  <LoadImage
                    imageUrl={selectedAnswer.image?.url}
                    imgAltText="answer"
                    isOption={true}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className={styles.answerDiv}>
              {renderOrderNumber(currentQuestion.answers, selectedAnswer)}{' '}
              {selectedAnswer.text}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={styles.correctAnswerIconAndScore}>
        <span className={styles.plusIconSvg}>
          <Points
            score={correctAnswerScore(
              choiceQuestionResponse.score,
              questionDifficultyLevel,
              questionDifficultySetting,
            )}
            speedScore={speedScore(
              choiceQuestionResponse.score,
              questionDifficultyLevel,
              questionDifficultySetting,
            )}
          />
        </span>

        {showDifficultyLevelIndicator && (
          <span>
            <img
              src={getDifficultyLevelIndicatorFlag(questionDifficultyLevel)}
              alt="diificulty level indicator flag"
              className={styles.difficultyLevelIndicatorFlag}
            />
          </span>
        )}
      </div>
    );
  };

  const renderCorrectAnswers = () => {
    const correctAnswers = (question as AdminQuestion).answers.filter(
      a => !!a.isCorrect,
    );
    if (!correctAnswers || correctAnswers.length === 0) {
      return null;
    }

    if (correct && selectedAnswer) {
      return selectedAnswer.image?.url ? (
        <div className={styles.selectedAnswerContainer}>
          <div className={styles.innerSelectedAnswerContainer}>
            <div className={styles.answerText}>{selectedAnswer.text}</div>
            {selectedAnswer.image?.url && (
              <LoadImage
                imageUrl={selectedAnswer.image?.url}
                imgAltText="answer"
                isOption={true}
              />
            )}
          </div>
        </div>
      ) : (
        <div className={styles.answerDivContainer}>
          <div className={styles.answerDiv} data-testid="correctAnswerText">
            {renderOrderNumber(currentQuestion.answers, selectedAnswer)}
            {selectedAnswer.text}
          </div>
        </div>
      );
    }
    return (
      <>
        <div className={styles.correctAnswerLabel}>
          {`Correct Answer${correctAnswers.length === 1 ? '' : 's'}:`}
        </div>

        <div className={styles.answerDivContainer}>
          {correctAnswers.map(a =>
            a.image?.url ? (
              <div className={styles.selectedAnswerContainer} key={a.id}>
                <div className={styles.innerSelectedAnswerContainer}>
                  <div className={styles.answerText}>{a.text}</div>
                  {a.image?.url && (
                    <LoadImage
                      imageUrl={a.image.url}
                      imgAltText="answer"
                      isOption={true}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.inverseAnswerDiv} key={a.id}>
                {renderOrderNumber(currentQuestion.answers, a)} {a.text}
              </div>
            ),
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {!showLeaderboard && (
        <div className={styles.container}>
          {renderResultAndScore()}
          {renderCorrectAnswers()}
        </div>
      )}
      {showLeaderboard && <QuestionFinishedLeaderboard />}
    </>
  );
};
