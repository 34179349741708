import React, { useEffect, useState } from 'react';
import { ActionButton } from '../../../components/action-button/ActionButton';
import { Button } from '../../../components/button/Button';
import { QuestionHeader } from '../../../components/currentQuestion/QuestionHeader';
import ProgressBar from '../../../components/progressbar/ProgressBar.module';
import { IAnswer } from '../../../models/Answer';
import { IChoiceQuestion } from '../../../models/ChoiceQuestion';
import { MultiSelectImageQuestionStarted } from './MultiSelectImageQuestionStarted';
import styles from './MultiSelectQuestionStarted.module.scss';
import { QuestionDifficultyLevel } from '@livepolls/ui-components/src/enums/question-difficulty-level.enum';

interface Props {
  question: IChoiceQuestion;
  onAnswerSelected(answerIds: string[]): () => Promise<void>;
  questionDifficultyLevel?: QuestionDifficultyLevel | null;
}

export const MultiSelectQuestionStarted = ({
  question,
  onAnswerSelected,
  questionDifficultyLevel,
}: Props) => {
  const answers = question.answers;
  const [showMultiSelectImageScreen, setShowMultiSelectImageScreen] =
    useState<boolean>(false);
  const [selectedAnswerIds, setSelectedAnswerIds] = useState<string[]>([]);

  const answerBtnDesktopEffects = !isTouchDevice()
    ? styles.answerBtnDesktopEffects
    : '';

  useEffect(() => {
    if (!showMultiSelectImageScreen) {
      if (!!question.image?.url) {
        setShowMultiSelectImageScreen(true);
      } else {
        const imagePresent = answers.some(answer => {
          return !!answer.image?.url;
        });

        if (imagePresent) {
          setShowMultiSelectImageScreen(true);
        }
      }
    }
  }, [question, answers, showMultiSelectImageScreen]);

  const selectAnswer = (answer: IAnswer) => {
    const index = selectedAnswerIds.findIndex(id => id === answer.id);
    const isPresent = index >= 0;
    if (isPresent) {
      const ids = selectedAnswerIds.filter(item => item !== answer.id);
      setSelectedAnswerIds(ids);
    } else {
      setSelectedAnswerIds(prevIds => [...prevIds, answer.id]);
    }
  };

  const trs: React.ReactElement<HTMLTableRowElement>[] = [];
  for (var i = 0; i < answers.length; i += 2) {
    const a1 = answers[i];
    const a2 = i + 1 < answers.length ? answers[i + 1] : null;

    const tr = (
      <tr key={i}>
        <td>
          <ActionButton
            className={styles.answerBtn + ' ' + answerBtnDesktopEffects}
            onClick={() => selectAnswer(a1)}
          >
            <div className={styles.answerRow}>
              <input
                className={styles.checkbox}
                type="checkbox"
                checked={
                  selectedAnswerIds.findIndex(item => item === a1.id) >= 0
                    ? true
                    : false
                }
                onChange={() => {}}
              />
              <div className={styles.answer}>
                <span>{orderNumberForIndex(i, answers.length)}</span>
                {a1.text}
              </div>
            </div>
          </ActionButton>
        </td>
        {a2 && (
          <td>
            <ActionButton
              className={styles.answerBtn + ' ' + answerBtnDesktopEffects}
              onClick={() => selectAnswer(a2)}
            >
              <div className={styles.answerRow}>
                <input
                  className={styles.checkbox}
                  type="checkbox"
                  checked={
                    selectedAnswerIds.findIndex(item => item === a2.id) >= 0
                      ? true
                      : false
                  }
                  onChange={() => {}}
                />
                <div className={styles.answer}>
                  <span>{orderNumberForIndex(i + 1, answers.length)}</span>{' '}
                  {a2.text}
                </div>
              </div>
            </ActionButton>
          </td>
        )}
        {!a2 && <td className={styles.emptyTd} />}
      </tr>
    );

    trs.push(tr);
  }

  const content = (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <tbody>{trs}</tbody>
      </table>
      <div
        className={styles.sendButton}
        onClick={onAnswerSelected(selectedAnswerIds)}
      >
        <Button label="Send" disable={selectedAnswerIds.length === 0} />
      </div>
    </div>
  );

  if (showMultiSelectImageScreen) {
    return (
      <MultiSelectImageQuestionStarted
        question={question as IChoiceQuestion}
        onAnswerSelected={onAnswerSelected}
      />
    );
  }

  return (
    <div className={styles.container}>
      <ProgressBar />

      <QuestionHeader
        question={question}
        questionDifficultyLevel={questionDifficultyLevel}
      />
      {content}
    </div>
  );
};

function letterForIndex(index: number) {
  return String.fromCharCode(97 + index);
}

function orderNumberForIndex(index: number, totalCount: number) {
  if (totalCount > 26) {
    return `${index + 1}) `;
  }

  return `${letterForIndex(index)}) `;
}

function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}
