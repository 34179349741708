export const getFontSizeForSwipeQuestionCard = (cardText: string) => {
  const cardTextLength = cardText.length;

  if (cardTextLength <= 60) {
    return '24px';
  } else if (cardTextLength <= 70) {
    return '22px';
  } else if (cardTextLength <= 90) {
    return '20px';
  } else if (cardTextLength <= 102) {
    return '18px';
  } else {
    return '16px';
  }
};
