import { ActionButton } from '../../../components/action-button/ActionButton';
import { LoadImage } from '../../../components/load-image/LoadImage';
import { IProfileQuestionAnswer } from '../../../models/ProfileQuestionAnswer';
import styles from './ProfileQuestionAnswers.module.scss';

interface Props {
  profileQuestionAnswers: IProfileQuestionAnswer[];
  onProfileQuestionAnswerSelected(
    profileQuestionAnswer: IProfileQuestionAnswer,
  ): void;
}

export const ProfileQuestionAnswers = ({
  profileQuestionAnswers,
  onProfileQuestionAnswerSelected,
}: Props) => {
  const showImageAnswers = profileQuestionAnswers.some(que => que.image?.url);

  const trs: React.ReactElement<HTMLTableRowElement>[] = [];
  for (var i = 0; i < profileQuestionAnswers.length; i += 2) {
    const a1 = profileQuestionAnswers[i];
    const a2 =
      i + 1 < profileQuestionAnswers.length
        ? profileQuestionAnswers[i + 1]
        : null;

    const tr = (
      <tr key={i}>
        <td>
          <ActionButton
            className={styles.answerBtn + ' ' + styles.answerBtnDesktopEffects}
            onClick={() => onProfileQuestionAnswerSelected(a1)}
          >
            {a1.text}
          </ActionButton>
        </td>
        {a2 && (
          <td>
            <ActionButton
              className={
                styles.answerBtn + ' ' + styles.answerBtnDesktopEffects
              }
              onClick={() => onProfileQuestionAnswerSelected(a2)}
            >
              {a2.text}
            </ActionButton>
          </td>
        )}
        {!a2 && <td className={styles.emptyTd} />}
      </tr>
    );

    trs.push(tr);
  }

  const imageAnswers = profileQuestionAnswers.map((answer, index) => {
    return (
      <div
        className={styles.answerContainer}
        key={answer.id}
        onClick={() => onProfileQuestionAnswerSelected(answer)}
      >
        <div className={styles.innerAnswerContainer}>
          <div className={styles.answerText}>{answer.text}</div>
          {answer.image?.url && (
            <LoadImage
              key={answer.id}
              imageUrl={answer.image.url}
              imgAltText="profile question answer"
              isOption={true}
            />
          )}
        </div>
      </div>
    );
  });

  return (
    <div className={styles.tableContainer}>
      {showImageAnswers ? (
        <div className={styles.imageAnswerList}>{imageAnswers}</div>
      ) : (
        <table className={styles.table}>
          <tbody>{trs}</tbody>
        </table>
      )}
    </div>
  );
};
