import { useEffect, useState } from 'react';
import { QuestionHeader } from '../../components/currentQuestion/QuestionHeader';
import { StartQuestionCountdown } from '../../models/StartQuestionCountdown';
import styles from './QuestionStartedCountDownScreen.module.scss';
interface Props {
  question?: StartQuestionCountdown;
  doNotPreloadImages?: boolean;
}
export const QuestionStartedCountDownScreen = ({
  question,
  doNotPreloadImages,
}: Props) => {
  const [preloadingStarted, setPreloadingStarted] = useState<boolean>(false);

  useEffect(() => {
    if (doNotPreloadImages) {
      return;
    }

    const firstCardImg =
      question?.cardImages &&
      question.cardImages.length > 0 &&
      question.cardImages[0].url;

    if (firstCardImg && !preloadingStarted) {
      preloadImage(firstCardImg);
      setPreloadingStarted(true);
    }
  }, [doNotPreloadImages, question, preloadingStarted]);

  return (
    <>
      {question && (
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <QuestionHeader question={question} />
          </div>
          <div className={styles.getReadyText}>Get ready...</div>
        </div>
      )}

      {question?.answerImages?.map((answer, index) => {
        return (
          <link
            rel="preload"
            href={answer.url}
            as="image"
            key={answer + ' ' + index}
            data-testid="preloadAnswerImage"
          />
        );
      })}

      {question?.image && (
        <link
          rel="preload"
          href={question.image.url}
          as="image"
          key={question.image.url}
          data-testid="preloadQuestionImage"
        />
      )}
    </>
  );
};

const preloadImage = (imgUrl: string) => {
  const img = new Image();
  img.src = imgUrl;
};
