import React, { useEffect, useRef, useState } from 'react';
import styles from './LivePollFinishedScreen.module.scss';

import { useParams } from 'react-router-dom';
import { HomeLink } from '../../components/header/HomeLink';
import { Spinner } from '../../components/spinner/Spinner';
import { LivePollFinishedReport } from '../../models/LivePollFinishedReport';
import { getApi, postApi } from '../utils/apiUtil';
import { SendMyResults } from './components/SendMyResults';
import { ThankYou } from './components/ThankYou';
import { removeSessionInfo } from '../../components/util/manageSessionInfo.util';
import { TemplatePreview } from './components/TemplatePreview';

export const LivePollFinishedScreen = () => {
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const params = useParams();
  const respondentUid = params.respondentUid;
  const [report, setReport] = React.useState<LivePollFinishedReport>();
  const thankYouRef = useRef<HTMLDivElement | null>(null);

  React.useMemo(async () => {
    const { data } = await getApi(`/take/respondent/${respondentUid}/report`);
    setReport(data);
  }, [respondentUid]);

  const [sendingEmail, setSendingEmail] = React.useState<boolean>(false);
  const [emailAddress, setEmailAddress] = React.useState<string | null>(null);
  const [emailSendError, setEmailSendError] = React.useState<Error | null>(
    null,
  );
  const [displayPreview, setDisplayPreview] = useState<boolean>(false);
  const [previewTemplateId, setPreviewTemplateId] = useState<number | null>(
    null,
  );

  removeSessionInfo();

  React.useEffect(() => {
    if (report?.respondentEmail) {
      setEmailAddress(report.respondentEmail);
    }
  }, [report?.respondentEmail]);

  const handleSendMyResults = async (emailAddress: string) => {
    try {
      setSendingEmail(true);
      await postApi(
        `/take/respondent/${respondentUid}/report-dashboard-email`,
        {
          emailAddress,
        },
      );
      setEmailAddress(emailAddress);
    } catch (err: any) {
      setEmailSendError(err);
    } finally {
      setSendingEmail(false);
    }
  };

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      scrollToTemplates();
    }, 5_000);

    return () => {
      clearTimeout(timeoutRef.current as any);
    };
  }, []);

  const onBackButtonClick = () => {
    setDisplayPreview(false);
    setPreviewTemplateId(null);
  };

  const displayPreviewScreen = (templateId: number) => {
    setDisplayPreview(true);
    setPreviewTemplateId(templateId);
  };

  const scrollToTemplates = () => {
    thankYouRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  const userIsTyping = () => {
    clearTimeout(timeoutRef.current as any);
  };

  const clickOnScroll = () => {
    clearTimeout(timeoutRef.current as any);

    thankYouRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  if (!report) {
    return <Spinner message="Loading Report" />;
  }

  if (sendingEmail) {
    return <Spinner message="Loading" />;
  }

  if (emailSendError) {
    return <div>Oops an error occurred ({emailSendError?.message}).</div>;
  }

  if (displayPreview && previewTemplateId) {
    return (
      <div className={styles.container}>
        <TemplatePreview
          templateId={previewTemplateId}
          onBackButtonClick={onBackButtonClick}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.homeLink}>
        <HomeLink />
      </div>
      <div className={styles.innerContainer}>
        {!emailAddress && (
          <SendMyResults
            report={report}
            onSendMyResultsClick={handleSendMyResults}
            userIsTyping={userIsTyping}
            clickOnScroll={clickOnScroll}
          />
        )}
        <div ref={thankYouRef} className={styles.thankYouContainer}>
          <ThankYou
            emailAddress={emailAddress!}
            displayPreviewScreen={displayPreviewScreen}
          />
        </div>
      </div>
    </div>
  );
};
