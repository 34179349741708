import { IProfileQuestion } from '../../models/ProfileQuestion';
import { IQuestion } from '../../models/Question';
import { StartQuestionCountdown } from '../../models/StartQuestionCountdown';
import { LpDifficultyLevelIndicator } from '@livepolls/ui-components/src/components/difficulty-level-indicator/LpDifficultyLevelIndicator';
import styles from './QuestionHeader.module.scss';
import { QuestionDifficultyLevel } from '@livepolls/ui-components/src/enums/question-difficulty-level.enum';
interface Props {
  question: IQuestion | StartQuestionCountdown | IProfileQuestion;
  text?: string;
  questionDifficultyLevel?: QuestionDifficultyLevel | null;
}

export const QuestionHeader = ({
  question,
  text,
  questionDifficultyLevel,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.questionNumberContainer}>
        {question.totalQuestionCount! > 1 && (
          <div data-testid="questionNumber">
            <span className={styles.currentQuestion}>
              {text}
              {question.currentQuestionNumber}
            </span>
            /{question.totalQuestionCount}
          </div>
        )}

        {questionDifficultyLevel != null && (
          <div className={styles.difficultyLevelIndicatorContainer}>
            <LpDifficultyLevelIndicator
              questionDifficultyLevel={questionDifficultyLevel}
              text={{ easy: 'Easy', normal: 'Normal', hard: 'Hard' }}
            />
          </div>
        )}
      </div>

      <header className={styles.header}>{question.title}</header>
    </div>
  );
};
