export const ArrowUp = () => {
  const image = (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Arrow up</title>
      <g
        id="Respondent-Flow-Mobile"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="R_11-Copy"
          transform="translate(-20.000000, -362.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <path
            d="M28.3080095,374 L23.6919905,374 C23.1821192,374 22.7687868,373.586817 22.7687868,373.077129 L22.7687868,368.462773 L20.4607773,368.462773 C20.2744277,368.461844 20.1069149,368.348995 20.0361035,368.176683 C19.9638182,368.004846 20.0020629,367.806409 20.1330399,367.673719 L25.6722627,362.136492 C25.7589361,362.049136 25.8769196,362 26,362 C26.1230804,362 26.2410639,362.049136 26.3277373,362.136492 L31.8669601,367.673719 C31.9979371,367.806409 32.0361818,368.004846 31.9638965,368.176683 C31.8930851,368.348995 31.7255723,368.461844 31.5392227,368.462773 L29.2312132,368.462773 L29.2312132,373.077129 C29.2312132,373.586817 28.8178808,374 28.3080095,374 Z"
            id="Arrow-up"
            transform="translate(26.000000, 368.000000) scale(1, -1) rotate(-180.000000) translate(-26.000000, -368.000000) "
          ></path>
        </g>
      </g>
    </svg>
  );
  return image;
};
