import styles from './Points.module.css';
import checkMark from '../../../images/right_tick.svg';
import speed from '../../../images/Speed.svg';
interface Props {
  score: number;
  speedScore: number;
}
export const Points = ({ score, speedScore }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.circle}>
        <img className={styles.image} src={checkMark} alt="right" />

        <div className={styles.imageOverlay}></div>
        <div className={styles.imageCircle}></div>
        <div className={styles.scoreOverlay}></div>
        <div className={styles.score} data-testid="score">
          +{score}
        </div>

        <div className={styles.speedImageOverlay}></div>
        <img className={styles.speedImage} src={speed} alt="right" />

        <div className={styles.speedOverlay}></div>
        <div className={styles.speed} data-testid="speed">
          +{speedScore}
        </div>

        <span
          className={styles.firework + ' ' + styles.fireworkBottomRight}
        ></span>

        <span
          className={styles.firework + ' ' + styles.fireworkBottomLeft}
        ></span>

        <span className={styles.firework + ' ' + styles.fireworkTopLeft}></span>

        <span
          className={styles.firework + ' ' + styles.fireworkTopRight}
        ></span>

        <span className={styles.firework + ' ' + styles.fireworkBottom}></span>

        <span className={styles.firework + ' ' + styles.fireworkTop}></span>

        <span className={styles.firework + ' ' + styles.fireworkRight}></span>

        <span className={styles.firework + ' ' + styles.fireworkLeft}></span>
      </div>
    </div>
  );
};
