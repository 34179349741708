import { getLightColor } from './getLightColor';
import { lightOrDark } from './isLightOrDark';
import { ThemeSettingFieldDto } from './theme.interface';

export const setTheme = (theme: ThemeSettingFieldDto) => {
  const color = theme.color;
  document.documentElement.style.setProperty('--answer', color['answer']);

  document.documentElement.style.setProperty('--main', color['main']);

  const mainColor = color['main'];

  document.documentElement.style.setProperty(
    '--button-background',
    color['button'],
  );

  const primaryColorLight = getLightColor(mainColor);

  document.documentElement.style.setProperty(
    '---light-main',
    primaryColorLight,
  );

  document.documentElement.style.setProperty(
    '--sessionName',
    color['sessionName'],
  );
  document.documentElement.style.setProperty('--title', color['title']);
  document.documentElement.style.setProperty('--cover', color['cover']);

  document.documentElement.style.setProperty(
    '--logo',
    encodeURIComponent(theme.logo),
  );
  document.documentElement.style.setProperty('--fontFamily', theme.font);

  const buttonColorLightOrDark = lightOrDark(color['button']);

  if (buttonColorLightOrDark === 'dark') {
    document.documentElement.style.setProperty('--button-text', '#fff');
    document.documentElement.style.setProperty('--tabColor', color['button']);
  } else {
    document.documentElement.style.setProperty('--button-text', '#555');
    document.documentElement.style.setProperty('--tabColor', color['main']);
  }
};
