import * as React from 'react';
import { useInterval } from '../../../hooks/useInterval';
import styles from './Trivia.module.scss';

export const Trivia = () => {
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);
  const [updateTriviaAfterMs, setUpdateTriviaAfterMs] =
    React.useState<number>(5000);

  React.useEffect(() => {
    const triviaItemLength = triviaItems[currentIndex].split(' ').length;
    if (triviaItemLength > 30) {
      setUpdateTriviaAfterMs(14000);
    } else if (triviaItemLength > 20) {
      setUpdateTriviaAfterMs(11000);
    } else if (triviaItemLength > 10) {
      setUpdateTriviaAfterMs(8000);
    }
  }, [currentIndex]);

  useInterval(() => {
    if (currentIndex >= triviaItems.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  }, updateTriviaAfterMs);

  return (
    <div className={styles.container}>
      <div className={styles.header}>Did you know?</div>
      <span className={styles.content}>{triviaItems[currentIndex]}</span>
    </div>
  );
};

const triviaItems = [
  'Did you know that Coca-Cola is one of the most valuable businesses in the world? A whopping 94% of the world’s population recognizes its iconic red and white branding.',
  'There are coffee houses in Russia where food and drink are free, but you pay for time.',
  'All employees of Ben & Jerry’s get to take home 3 free pints of ice cream every single day!',
  'Americans buying a Volvo can get a free holiday to Sweden to test drive their new car. All costs, including airfare and shipment of the car, are covered by the Volvo Overseas Delivery program.',
  "As a reward for record profits, the CEO of Lenovo, Yang Yuanqing, received a $3 million bonus, which he redistributed to about 10,000 of Lenovo's employees. He did the same again in 2013.",
  'Rather than using mechanical mowers, Google regularly employs a flock of 200 goats to naturally mow and fertilize the lawns.',
  "In response to China's air pollution, a Chinese millionaire started selling cans of fresh air for $0.80 and made over $6 million dollars in 10 months.",
  "Every day, 1% of the world's population is served at McDonald's.",
  'The Shell Oil Company began as a novelty shop in London that sold seashells.',
  'Samsung, the largest business conglomerate in South Korea, began as a trucking company.',
  'The upside-down ketchup bottle earned its inventor 13 million dollars.',
  'In 2013, Candy Crush made $850,000 per day!',
  "There's a café in France which charges €7 for a coffee to rude customers and €1.40 to people who talk politely to staff.",
  'Nokia was once famous as a manufacturer of toilet paper.',
  'The co-founder and CEO of SnapChat, 23-year-old Evan Spiegel, turned down an offer of $3 billion from Facebook and then a $4 billion bid from Google.',
  "A Kodak engineer constructed the first digital camera in 1975. They didn't want to go digital. They went bankrupt in 2012.",
  "As part of an advertising campaign, Molson, a Canadian beer company, strategically placed 'beer fridges' around Europe that only Canadian passports could unlock.",
  'The Gmail logo was designed the night before it was launched.',
  'Dell Computers was started by a 19-year-old with only $1,000.',
  "Dell's first advertisement was made on the back of a pizza box.",
  'All 3 founders of Apple worked at Atari before forming Apple.',
  "The founder of McDonald's has a Bachelor’s degree in Hamburgerology.",
  'Duracell, the battery-maker, built parts of its new international headquarters using materials from its own waste.',
  'Microsoft made $16,005 in revenue in its first year of operation.',
  "Warner Chappel Music owns the copyright to the song 'Happy Birthday'. They make over $1 million in royalties every year from the commercial use of the song.",
  'The first product that Sony came out with was the rice cooker.',
];
