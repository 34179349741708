import { useEffect, useState } from 'react';
import { WordCloudAnswerSvg } from '../../../components/svgImages/wordCloudAnswersSvg';
import { WordCloudSvg } from '../../../components/svgImages/wordCloudSvg';
import { WordCloud } from '../../../components/word-cloud/WordCloud';
import {
  IWordCloudResponse,
  useTakeLivePollState,
} from '../../../contexts/takeLivePollStateContext';
import styles from './WordCloudQuestionFinished.module.scss';

enum TABS {
  ANSWERS,
  WORD_CLOUD,
}

const TOP_N_RESPONSES = 10;
export const WordCloudQuestionFinished = () => {
  const [activeTab, setActiveTab] = useState<TABS>(TABS.ANSWERS);

  const { serverState } = useTakeLivePollState();
  const [sortedWordCloudResponses, setSortedWordCloudResponses] = useState<
    IWordCloudResponse[]
  >([]);

  useEffect(() => {
    if (serverState.wordCloudResponses) {
      const sortedWordCloudResponses = [...serverState.wordCloudResponses];
      sortedWordCloudResponses.sort(
        (wordCloudResponse1, wordCloudResponse2) =>
          wordCloudResponse2.weight - wordCloudResponse1.weight,
      );
      setSortedWordCloudResponses(sortedWordCloudResponses);
    }
  }, [serverState.wordCloudResponses]);

  const topTenResponses =
    sortedWordCloudResponses.length > TOP_N_RESPONSES
      ? sortedWordCloudResponses.slice(0, TOP_N_RESPONSES)
      : sortedWordCloudResponses;

  const answerTabActive = activeTab === TABS.ANSWERS;
  const wordCloudTabActive = activeTab === TABS.WORD_CLOUD;
  const answerTabContent = (
    <div className={styles.answerTabContainer}>
      <span className={styles.contentHeading}>Answers</span>
      <div className={styles.answerTabTextContent}>
        {topTenResponses.map((wordCloudResponse, index) => (
          <div className={styles.answerContainer} key={index}>
            <span className={styles.answerText}>{wordCloudResponse.name}</span>
            <span className={styles.answerMentions}>
              Mentions: {wordCloudResponse.weight}
            </span>
          </div>
        ))}
      </div>
    </div>
  );

  const wordCloudContent = (
    <>
      <span className={styles.contentHeading}>Word Cloud</span>
      <div className={styles.wordCloudTabTextContent}>
        <WordCloud data={sortedWordCloudResponses} />
      </div>
    </>
  );
  const tabColor =
    document.documentElement.style.getPropertyValue('--button-background') ||
    '#1b87e6';
  return (
    <div className={styles.container}>
      <div className={styles.tabContainer}>
        <div
          className={`${styles.positionedAbsBottom} ${styles.horizontalLine}`}
        ></div>
        <div
          className={`${styles.tab} ${answerTabActive ? styles.activeTab : ''}`}
          onClick={() => setActiveTab(TABS.ANSWERS)}
        >
          <span className={styles.icon}>
            <WordCloudAnswerSvg color={answerTabActive ? tabColor : '#000'} />
          </span>
          <span>Answers</span>
        </div>
        <div
          className={`${styles.tab} ${
            wordCloudTabActive ? styles.activeTab : ''
          }`}
          onClick={() => setActiveTab(TABS.WORD_CLOUD)}
        >
          <span className={styles.icon}>
            <WordCloudSvg color={wordCloudTabActive ? tabColor : '#000'} />
          </span>
          <span>Word Cloud</span>
        </div>
      </div>

      <div className={styles.contentContainer}>
        {answerTabActive && answerTabContent}
        {wordCloudTabActive && wordCloudContent}
      </div>
    </div>
  );
};
