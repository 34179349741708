import { IQuestionResponseTimer } from '../../models/QuestionResponseTimer';
import { getApi } from './apiUtil';

export const getCorrectTimeInMs = (clientServerTimeDiffInMs?: number) => {
  if (clientServerTimeDiffInMs) {
    return Date.now() - clientServerTimeDiffInMs;
  }
  return Date.now();
};

export const calculateTimeRemainingInMs = ({
  questionResponseTimer,
  clientServerTimeDiffInMs,
}: {
  questionResponseTimer?: IQuestionResponseTimer;
  clientServerTimeDiffInMs: number;
}) => {
  const endAt = questionResponseTimer?.endAt;
  if (!endAt) {
    return Number.MAX_SAFE_INTEGER;
  }

  const timeRemaining =
    new Date(endAt).getTime() - getCorrectTimeInMs(clientServerTimeDiffInMs);
  return timeRemaining;
};

export const calculateStartDelayInMs = ({
  questionResponseTimer,
  clientServerTimeDiffInMs,
}: {
  questionResponseTimer?: IQuestionResponseTimer;
  clientServerTimeDiffInMs: number;
}): number => {
  const startAt = questionResponseTimer?.startAt;
  if (!startAt) {
    return 0;
  }

  const startDelayInMs =
    new Date(startAt).getTime() - getCorrectTimeInMs(clientServerTimeDiffInMs);
  return startDelayInMs;
};

/**
 *
 * @returns number, which is the difference between client's time and server's time
 * WHY - We display Time Up, when question's endAt value is less than respondent's current timestamp
 *       But in scenarios where the respondent's system time is not correct (e.g. not set to auto-adjust from
 *       the internet) this check fails.
 *       So this method is to sync the respondent's time to the correct time.
 */
export const getClientServerTimeDiffInMs = async (): Promise<number> => {
  try {
    const standardErrorInMs = 300;
    const startTime = Date.now();
    const { data: serverUtcTime } = await getApi('/take/respondent/utc-time');

    // divide bby 2 because we are not considering latency
    const timeTakenToGetUtcTime = Math.round((Date.now() - startTime) / 2);

    // add timeTakenToGetUtcTime to serverUtcTime, to get the actual server time
    const actualServerUtcTime =
      new Date(serverUtcTime).getTime() + timeTakenToGetUtcTime;

    const clientServerTimeDiffInMs = Date.now() - actualServerUtcTime;
    return Math.abs(clientServerTimeDiffInMs) < standardErrorInMs
      ? 0
      : clientServerTimeDiffInMs;
  } catch (error: any) {
    console.error('error in getting UTC time ', error);
    return 0;
  }
};
