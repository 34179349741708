import React from 'react';
import { useTakeLivePollState } from '../../../contexts/takeLivePollStateContext';
import styles from './QuestionFinishedLeaderboard.module.scss';
import { LeaderboardSvg } from '../../../components/svgImages/leaderboardSvg';
import { Leaderboard } from '../../../components/leaderboard/Leaderboard';
import { TeamLeaderboard } from '../../../components/team-leaderboard/TeamLeaderboard';

export const QuestionFinishedLeaderboard = () => {
  const { serverState } = useTakeLivePollState();
  const { leaderboard, respondent, previousRank, rank, livePollSession } =
    serverState;

  const sortedRef = React.useRef<boolean>(false);
  if (!sortedRef.current) {
    sortedRef.current = true;
  }
  const isTeamsEnabled = !!livePollSession?.setting?.teamSetting?.enabled;

  return (
    <div className={styles.container}>
      <div className={styles.leaderboardImg}>
        <span>
          <LeaderboardSvg />
        </span>
      </div>

      <div className={styles.message}>
        <div className={styles.message1}>Look up!</div>
        <div className={styles.message2} data-testid="leaderboardMsg">
          <div>The leaderboard is on your host's screen!</div>
        </div>
      </div>

      {isTeamsEnabled && (
        <TeamLeaderboard respondentTeamUid={respondent.teamUid!} />
      )}

      {!isTeamsEnabled && (
        <Leaderboard
          leaderboard={leaderboard}
          respondentId={respondent.id}
          previousRank={previousRank}
          rank={rank}
        />
      )}
    </div>
  );
};
