import { useTakeLivePollState } from '../../../contexts/takeLivePollStateContext';
import { Team } from '../../../models/Team';
import styles from './SelectTeamScreen.module.css';
import { TeamSelectionDropDown } from './TeamSelectionDropDown';

interface Props {
  onChangeRespondentTeam: (team: Team) => void;
}

export const SelectTeamScreen = ({ onChangeRespondentTeam }: Props) => {
  const { serverState } = useTakeLivePollState();
  const { livePollSession } = serverState;
  const livePollSessionName = livePollSession?.name;

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.welcomeMessage}>
          {livePollSessionName ? <div>Welcome to</div> : <div>Welcome</div>}
          {livePollSessionName && (
            <div className={styles.livePollSessionName}>
              {livePollSessionName}
            </div>
          )}
        </div>

        <div>Select your team to proceed</div>

        <TeamSelectionDropDown
          changeRespondentTeam={team => onChangeRespondentTeam(team)}
          text="Select team"
        />
      </div>
    </div>
  );
};
