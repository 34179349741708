export const TeamsUserSvg = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="currentColor" />
      <path
        d="M24 19C21.7909 19 20 20.7909 20 23C20 25.2091 21.7909 27 24 27C26.2091 27 28 25.2091 28 23C28 20.7909 26.2091 19 24 19Z"
        fill="white"
      />
      <path
        d="M22.6667 28.3333C18.9848 28.3333 16 31.3181 16 35H32C32 31.3181 29.0152 28.3333 25.3333 28.3333H22.6667Z"
        fill="white"
      />
      <path
        d="M36.0156 15.0156C34.3588 15.0156 33.0156 16.3588 33.0156 18.0156C33.0156 19.6725 34.3588 21.0156 36.0156 21.0156C37.6725 21.0156 39.0156 19.6725 39.0156 18.0156C39.0156 16.3588 37.6725 15.0156 36.0156 15.0156Z"
        fill="white"
      />
      <path
        d="M35.0156 22.0156C32.2542 22.0156 30.0156 24.2542 30.0156 27.0156H42.0156C42.0156 24.2542 39.777 22.0156 37.0156 22.0156H35.0156Z"
        fill="white"
      />
      <path
        d="M12.0156 15.0156C10.3588 15.0156 9.01562 16.3588 9.01562 18.0156C9.01562 19.6725 10.3588 21.0156 12.0156 21.0156C13.6725 21.0156 15.0156 19.6725 15.0156 18.0156C15.0156 16.3588 13.6725 15.0156 12.0156 15.0156Z"
        fill="white"
      />
      <path
        d="M11.0156 22.0156C8.2542 22.0156 6.01562 24.2542 6.01562 27.0156H18.0156C18.0156 24.2542 15.777 22.0156 13.0156 22.0156H11.0156Z"
        fill="white"
      />
    </svg>
  );
};
