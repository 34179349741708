import React, { useEffect, useState } from 'react';
import { ActionButton } from '../../../components/action-button/ActionButton';
import { Button } from '../../../components/button/Button';
import { QuestionHeader } from '../../../components/currentQuestion/QuestionHeader';
import { LoadImage } from '../../../components/load-image/LoadImage';
import ProgressBar from '../../../components/progressbar/ProgressBar.module';
import { IAnswer } from '../../../models/Answer';
import { IChoiceQuestion } from '../../../models/ChoiceQuestion';
import styles from './MultiSelectImageQuestionStarted.module.scss';

interface Props {
  question: IChoiceQuestion;
  onAnswerSelected(answerIds: string[]): () => Promise<void>;
}

export const MultiSelectImageQuestionStarted = ({
  question,
  onAnswerSelected,
}: Props) => {
  const answers = question.answers;
  const [showImageAnswer, setShowImageAnswer] = useState<boolean>(false);

  const [showQuestionImage, setShowQuestionImage] = useState<boolean>(false);

  const [selectedAnswerIds, setSelectedAnswerIds] = useState<string[]>([]);

  const answerBtnDesktopEffects = !isTouchDevice()
    ? styles.answerBtnDesktopEffects
    : '';

  let content;
  useEffect(() => {
    if (!showQuestionImage) {
      if (!!question.image?.url) {
        setShowQuestionImage(true);
      }
    }

    if (!showImageAnswer) {
      const imagePresent = answers.some(answer => {
        return !!answer.image?.url;
      });

      if (imagePresent) {
        setShowImageAnswer(true);
      }
    }
  }, [question, answers, showImageAnswer, showQuestionImage]);

  const selectAnswer = (answer: IAnswer) => {
    const index = selectedAnswerIds.findIndex(id => id === answer.id);
    const isPresent = index >= 0;
    if (isPresent) {
      const ids = selectedAnswerIds.filter(item => item !== answer.id);
      setSelectedAnswerIds(ids);
    } else {
      setSelectedAnswerIds(prevIds => [...prevIds, answer.id]);
    }
  };

  const trs: React.ReactElement<HTMLTableRowElement>[] = [];
  for (var i = 0; i < answers.length; i += 2) {
    const a1 = answers[i];
    const a2 = i + 1 < answers.length ? answers[i + 1] : null;

    const tr = (
      <tr key={i}>
        <td>
          <ActionButton
            className={styles.answerBtn + ' ' + answerBtnDesktopEffects}
            onClick={() => selectAnswer(a1)}
          >
            <div className={styles.answerRow}>
              <input
                className={styles.checkbox}
                type="checkbox"
                checked={
                  selectedAnswerIds.findIndex(item => item === a1.id) >= 0
                    ? true
                    : false
                }
                onChange={() => {}}
              />
              <div className={styles.answer}>
                <span>{orderNumberForIndex(i, answers.length)}</span>
                {a1.text}
              </div>
            </div>
          </ActionButton>
        </td>
        {a2 && (
          <td>
            <ActionButton
              className={styles.answerBtn + ' ' + answerBtnDesktopEffects}
              onClick={() => selectAnswer(a2)}
            >
              <div className={styles.answerRow}>
                <input
                  className={styles.checkbox}
                  type="checkbox"
                  checked={
                    selectedAnswerIds.findIndex(item => item === a2.id) >= 0
                      ? true
                      : false
                  }
                  onChange={() => {}}
                />
                <div className={styles.answer}>
                  <span>{orderNumberForIndex(i + 1, answers.length)}</span>{' '}
                  {a2.text}
                </div>
              </div>
            </ActionButton>
          </td>
        )}
        {!a2 && <td className={styles.emptyTd} />}
      </tr>
    );

    trs.push(tr);
  }

  const sendButton = (
    <div
      className={styles.sendButton}
      onClick={onAnswerSelected(selectedAnswerIds)}
    >
      <Button label="Send" disable={selectedAnswerIds.length === 0} />
    </div>
  );

  const imageAnswers = answers.map(answer => {
    return (
      <div
        className={styles.answerContainer}
        key={answer.id}
        onClick={() => selectAnswer(answer)}
      >
        <div className={styles.innerAnswerContainer}>
          <div className={styles.innerImageAnswerContainer}>
            <input
              className={styles.checkbox}
              type="checkbox"
              checked={
                selectedAnswerIds.findIndex(item => item === answer.id) >= 0
                  ? true
                  : false
              }
              onChange={() => {}}
            />
            <div className={styles.answerText}>{answer.text}</div>
          </div>

          {answer.image?.url && (
            <LoadImage
              key={answer.id}
              imageUrl={answer.image.url}
              imgAltText="answer"
              isOption={true}
            />
          )}
        </div>
      </div>
    );
  });

  if (showImageAnswer && showQuestionImage) {
    //both (question and answers) has images
    content = (
      <div className={styles.questionAnswer}>
        <div className={styles.questionImage}>
          <LoadImage imageUrl={question.image.url} imgAltText="question" />
        </div>
        <div className={styles.imageAnswerList}>{imageAnswers}</div>
      </div>
    );
  } else if (showImageAnswer && !showQuestionImage) {
    //only answers has images
    content = <div className={styles.imageAnswers}>{imageAnswers}</div>;
  } else if (!showImageAnswer && showQuestionImage) {
    //only question has image
    content = (
      <div className={styles.tableContainer}>
        <div className={styles.questionImage}>
          <LoadImage imageUrl={question.image.url} imgAltText="question" />
        </div>
        <table className={styles.table}>
          <tbody>{trs}</tbody>
        </table>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <ProgressBar />

      <QuestionHeader question={question} />
      {content}
      {sendButton}
    </div>
  );
};

function letterForIndex(index: number) {
  return String.fromCharCode(97 + index);
}

function orderNumberForIndex(index: number, totalCount: number) {
  if (totalCount > 26) {
    return `${index + 1}) `;
  }

  return `${letterForIndex(index)}) `;
}

function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}
