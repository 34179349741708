import * as React from 'react';
import styles from './Spinner.module.css';

interface Props {
  color?: string;
  message?: string;
  count?: number;
}

interface State {
  spinnerText: string | undefined;
  intervalId: NodeJS.Timeout | null;
  spinnerTextIndex: number;
}
export class Spinner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      spinnerText: props.message,
      spinnerTextIndex: 0,
      intervalId: null,
    };
  }

  componentDidMount() {
    const id = setInterval(() => {
      this.setState({ spinnerTextIndex: this.state.spinnerTextIndex + 1 });
    }, 3000);
    this.setState({ intervalId: id });
  }

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }
  getMessage(index: number) {
    if (index >= LoadingText.length) {
      return LoadingText[LoadingText.length - 1];
    }
    if (index === 0 && this.props.message) {
      return this.props.message;
    }
    return LoadingText[index];
  }

  public render() {
    const { color, count } = this.props;
    const { spinnerTextIndex } = this.state;
    const spinnerMessage = this.getMessage(spinnerTextIndex);
    return (
      <div data-testid="spinner">
        <div
          className={styles.spinner}
          style={color ? { backgroundColor: color } : {}}
        >
          <div className={styles.bounce1}></div>
          <div className={styles.bounce2}></div>
          <div className={styles.bounce3}></div>
        </div>
        <div
          className={styles.loader}
          style={color ? { borderTopColor: color } : {}}
        />
        <div className={styles.loaderMessage}>
          {spinnerMessage} {count && count > 0 ? [<span>{count}</span>] : ''}
        </div>
      </div>
    );
  }
}

const LoadingText = [
  'Loading...',
  'Hmm... still loading',
  "Let's see - still waiting",
  'Knock knock - is anyone there',
  'Seems like we broke... hit refresh?',
];
