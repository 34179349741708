import * as React from 'react';
import styles from './InputEmail.module.scss';
import emailIcon from '../../images/icons_email_blue.svg';

interface Props {
  text: string;
  placeholder: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void;
}

export const InputEmail = ({
  text,
  placeholder,
  onChange,
  onKeyPress,
}: Props) => {
  return (
    <div className={styles.container}>
      <input
        type="email"
        placeholder={placeholder}
        value={text}
        onChange={onChange}
        style={text ? { backgroundImage: `url(${emailIcon})` } : {}}
        maxLength={320}
        onKeyPress={onKeyPress}
      />

      <div className={styles.line} />
    </div>
  );
};
