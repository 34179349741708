import React, { useEffect, useRef, useState } from 'react';
import { useTakeLivePollState } from '../../contexts/takeLivePollStateContext';
import { LivePollType } from '../../models/LivePollType';
import { IQuestionResponseTimer } from '../../models/QuestionResponseTimer';
import styles from './ProgressBar.module.css';

const calculateTimeElapsedInMs = (
  questionResponseTimer?: IQuestionResponseTimer,
) => {
  const startAt = questionResponseTimer?.startAt;
  if (!startAt) {
    return 0;
  }
  return Date.now() - new Date(startAt).getTime();
};

const calculateTotalTimeInMs = (
  questionResponseTimer?: IQuestionResponseTimer,
) => {
  const endAt = questionResponseTimer?.endAt;
  if (!endAt) {
    return 0;
  }
  const startAt = questionResponseTimer?.startAt;
  if (!startAt) return 0;

  const totalTimeInMs = new Date(endAt).getTime() - new Date(startAt).getTime();
  return totalTimeInMs;
};

const calculatePercentage = (total: number, currentValue: number) => {
  if (total <= 0) {
    return 0;
  }
  return (currentValue / total) * 100;
};

const ProgressBar = () => {
  const { serverState } = useTakeLivePollState();
  const { questionResponseTimer, livePollSession } = serverState;
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);

  const totalTimeInMsRef = useRef<number>(
    calculateTotalTimeInMs(questionResponseTimer),
  );
  const [timeElapsedPercentage, setTimeElapsedPercentage] = useState<number>(
    calculatePercentage(
      totalTimeInMsRef.current,
      calculateTimeElapsedInMs(questionResponseTimer),
    ),
  );

  const isQuiz = livePollSession?.livePollType === LivePollType.QUIZ;

  useEffect(() => {
    if (timeElapsedPercentage >= 100) {
      return;
    }
    timerRef.current = setTimeout(() => {
      const percentage = calculatePercentage(
        totalTimeInMsRef.current,
        calculateTimeElapsedInMs(questionResponseTimer),
      );
      setTimeElapsedPercentage(percentage);
    }, 62);
  }, [isQuiz, questionResponseTimer, timeElapsedPercentage]);

  useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, []);

  if (timeElapsedPercentage === 0) {
    return null;
  }

  return (
    <div className={styles.progress}>
      <div
        style={{ width: timeElapsedPercentage + '%' }}
        className={styles.progressbar}
        data-testid="progressBar"
      />
    </div>
  );
};

export default ProgressBar;
