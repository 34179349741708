import { useCallback, useEffect, useRef, useState } from 'react';
import { QuestionHeader } from '../../../../components/currentQuestion/QuestionHeader';
import { ISwipeQuestion } from '../../../../models/SwipeQuestion';
import { SwipeQuestionOptionSide } from '../../../../models/SwipeQuestionOptionSide.enum';
import styles from './SwipeQuestionStarted.module.scss';
import { ISwipeQuestionCard } from '../../../../models/SwipeQuestionCard';
import { Airplane } from '../../../../components/animation/airplane/Airplane';
import { SwipeLeftSvg } from '../../../../components/svgImages/swipeLeftSvg';
import { SwipeRightSvg } from '../../../../components/svgImages/swipeRightSvg';
import { SHOW_SWIPE_TUTORIAL_KEY } from '../../../../constants/local-storage-keys.constants';
import { ImageCard } from './components/ImageCard';
import { TextCard } from './components/TextCard';
import { SwipeBtnDesktop } from './components/SwipeBtnDesktop';
import { SwipeLabelMobile } from './components/SwipeLabelMobile';
import ProgressBar from '../../../../components/progressbar/ProgressBar.module';
import { ISelectedCard } from '../../../../models/SwipeQuestionSelectedCard';
interface Props {
  question: ISwipeQuestion;
  submitSwipeAnswer(questionId: string, selectedCards: ISelectedCard[]): void;
}

interface CardSwipeProperties {
  originalCardPositionX: number;
  originalCardPositionY: number;
  diffX: number;
  diffY: number;
  isCardMoving: boolean;
  leftColorRgbArray: number[];
  rightColorRgbArray: number[];
  minThresholdForSwipe: number;
}
interface CardMoveEffect {
  gradientDeg: number;
  translate: [number, number];
  rotationDeg: number;
  transition: string;
  rgbArray: number[];
  progress: number;
}

const LEFT_GRADIENT_DEG = 38;
const RIGHT_GRADIENT_DEG = 328;
const WHITE_COLOR_RGB = [255, 255, 255];
const SMALL_DEVICE_WIDTH = 600;
const VALID_SWIPE_THRESHOLD_FACTOR = 0.6;
const VALID_SWIPE_THRESHOLD_FACTOR_SMALL_DEVICE = 0.4;
const LEFT_SWIPE_COLOR = '#FF7681';
const RIGHT_SWIPE_COLOR = '#3FCA7F';

export const SwipeQuestionStarted = ({
  question,
  submitSwipeAnswer,
}: Props) => {
  const swipeOptions = question.swipeOptions;

  const [cardSwipeDirection, setCardSwipeDirection] =
    useState<SwipeQuestionOptionSide | null>(null);
  const [cards, setCards] = useState<ISwipeQuestionCard[]>([...question.cards]);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const [submitResponse, setSubmitResponse] = useState<boolean>(false);
  const [showSwipeTutorial, setShowSwipeTutorial] = useState<boolean>(false);
  const [selectedCards, setSelectedCards] = useState<ISelectedCard[]>([]);
  const [selectedCardDirection, setSelectedCardDirection] =
    useState<SwipeQuestionOptionSide | null>(null);

  const mountedRef = useRef<boolean>(true);
  const cardRef = useRef<HTMLDivElement | null>(null);
  const gradientOverlayRef = useRef<HTMLDivElement | null>(null);
  const cardAnswerTextRowDivRef = useRef<HTMLDivElement | null>(null);

  const cardSwipePropertiesRef = useRef<CardSwipeProperties | null>({
    originalCardPositionX: 0,
    originalCardPositionY: 0,
    diffX: 0,
    diffY: 0,
    leftColorRgbArray: hexToRgbArray(LEFT_SWIPE_COLOR),
    rightColorRgbArray: hexToRgbArray(RIGHT_SWIPE_COLOR),
    minThresholdForSwipe: 0,
    isCardMoving: false,
  });
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const showTutorialTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const mouseMove = useCallback(
    (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      if (
        !cardRef.current ||
        !cardSwipePropertiesRef.current ||
        !gradientOverlayRef.current ||
        !cardAnswerTextRowDivRef.current ||
        !isMouseDown
      ) {
        return;
      }

      const {
        originalCardPositionX,
        originalCardPositionY,
        leftColorRgbArray,
        rightColorRgbArray,
        minThresholdForSwipe,
      } = cardSwipePropertiesRef.current;

      const currentClientX = getClientX(e);
      const currentClientY = getClientY(e);
      const diffX = currentClientX - originalCardPositionX;
      const diffY = currentClientY - originalCardPositionY;

      const absDiff = Math.abs(diffX);
      const progress = Math.min(absDiff / minThresholdForSwipe, 1);

      let rotationDeg = 0;
      let gradientDeg = 0;
      let cardDirection = 0;
      let rgb: number[] = [];

      cardSwipePropertiesRef.current.diffX = diffX;
      cardSwipePropertiesRef.current.diffY = diffY;
      if (diffX < 0) {
        // swipe left
        rotationDeg = Math.min(absDiff / 15, 50);
        gradientDeg = LEFT_GRADIENT_DEG;
        rgb = leftColorRgbArray;

        cardDirection = SwipeQuestionOptionSide.LEFT;
      } else if (diffX > 0) {
        // swipe right
        rotationDeg = -Math.min(absDiff / 15, 50);
        gradientDeg = RIGHT_GRADIENT_DEG;
        rgb = rightColorRgbArray;

        cardDirection = SwipeQuestionOptionSide.RIGHT;
      }

      setCardSwipeDirection(prevDirection =>
        prevDirection !== cardDirection ? cardDirection : prevDirection,
      );

      applyCardMoveEffect(
        cardRef.current,
        gradientOverlayRef.current,
        cardAnswerTextRowDivRef.current,
        {
          rotationDeg,
          translate: [diffX, diffY],
          gradientDeg,
          transition: 'none',
          progress,
          rgbArray: rgb,
        },
      );
    },
    [isMouseDown],
  );

  const cardTransitionEnd = useCallback((removeCard: boolean = true) => {
    if (!mountedRef.current) {
      return;
    }
    timeoutRef.current = setTimeout(() => {
      if (cardSwipePropertiesRef.current) {
        cardSwipePropertiesRef.current.isCardMoving = false;
      }
      if (removeCard) {
        setCards(oldCards => [...oldCards.slice(1)]);
        setCardSwipeDirection(null);
      }
    }, 450);
  }, []);

  const mouseUp = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (
        !cardRef.current ||
        !gradientOverlayRef.current ||
        !cardSwipePropertiesRef.current ||
        !cardAnswerTextRowDivRef.current ||
        !isMouseDown
      ) {
        return;
      }

      setIsMouseDown(false);
      cardSwipePropertiesRef.current.isCardMoving = true;

      const {
        diffX,
        leftColorRgbArray,
        rightColorRgbArray,
        minThresholdForSwipe,
      } = cardSwipePropertiesRef.current;
      const absDiffX = Math.abs(diffX);

      if (absDiffX >= minThresholdForSwipe) {
        // swipe card
        let cardSwipeDirection: SwipeQuestionOptionSide | null = null;
        if (diffX < 0) {
          moveCardLeft(
            cardRef.current,
            gradientOverlayRef.current,
            cardAnswerTextRowDivRef.current,
            leftColorRgbArray,
          );
          cardSwipeDirection = SwipeQuestionOptionSide.LEFT;
        } else {
          moveCardRight(
            cardRef.current,
            gradientOverlayRef.current,
            cardAnswerTextRowDivRef.current,
            rightColorRgbArray,
          );
          cardSwipeDirection = SwipeQuestionOptionSide.RIGHT;
        }
        setSelectedCardDirection(cardSwipeDirection);
        cardTransitionEnd();
      } else {
        const transition = 'transform 0.7s ease, background 0.7s ease';
        // back to original position
        applyCardMoveEffect(
          cardRef.current,
          gradientOverlayRef.current,
          cardAnswerTextRowDivRef.current,
          {
            rotationDeg: 0,
            translate: [0, 0],
            gradientDeg: 0,
            transition,
            rgbArray: WHITE_COLOR_RGB,
            progress: 0,
          },
        );
        setCardSwipeDirection(null);
        cardTransitionEnd(false);
      }
    },
    [isMouseDown, cardTransitionEnd],
  );

  const mouseDown = useCallback(
    (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      if (
        !cardRef.current ||
        !cardSwipePropertiesRef.current ||
        cardSwipePropertiesRef.current.isCardMoving ||
        isMouseDown
      ) {
        return;
      }

      setIsMouseDown(true);
      const cardWidth = cardRef.current.offsetWidth;
      const minThresholdForSwipe =
        window.innerWidth <= SMALL_DEVICE_WIDTH
          ? cardWidth * VALID_SWIPE_THRESHOLD_FACTOR_SMALL_DEVICE
          : cardWidth * VALID_SWIPE_THRESHOLD_FACTOR;

      cardSwipePropertiesRef.current = {
        ...cardSwipePropertiesRef.current,
        diffX: 0,
        diffY: 0,
        originalCardPositionX: getClientX(e),
        originalCardPositionY: getClientY(e),
        minThresholdForSwipe,
      };
    },
    [isMouseDown],
  );

  // for mounting and unmounting
  useEffect(() => {
    const swipeTutorial = localStorage.getItem(SHOW_SWIPE_TUTORIAL_KEY);
    if (swipeTutorial == null) {
      showTutorialTimeoutRef.current = setTimeout(() => {
        setShowSwipeTutorial(false);
        localStorage.setItem(SHOW_SWIPE_TUTORIAL_KEY, 'true');
      }, 4050);
      setShowSwipeTutorial(true);
    }

    return () => {
      mountedRef.current = false;
      timeoutRef.current && clearTimeout(timeoutRef.current);
      showTutorialTimeoutRef.current &&
        clearTimeout(showTutorialTimeoutRef.current);
    };
  }, []);

  // to clean up event listeners on document
  useEffect(() => {
    if (!cardRef.current) {
      return;
    }

    cardRef.current.style.cursor = 'pointer';
    cardRef.current.addEventListener('mousedown', mouseDown);
    cardRef.current.addEventListener('touchstart', mouseDown, {
      passive: false,
    });

    if (isMouseDown) {
      document.addEventListener('mousemove', mouseMove);
      document.addEventListener('touchmove', mouseMove, { passive: false });
      document.addEventListener('mouseup', mouseUp);
      document.addEventListener('touchend', mouseUp);
    } else {
      document.removeEventListener('mousemove', mouseMove);
      document.removeEventListener('touchmove', mouseMove);
      document.removeEventListener('mouseup', mouseUp);
      document.removeEventListener('touchend', mouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', mouseMove);
      document.removeEventListener('touchmove', mouseMove);
      document.removeEventListener('mouseup', mouseUp);
      document.removeEventListener('touchend', mouseUp);
    };
  }, [isMouseDown, mouseDown, mouseMove, mouseUp]);

  useEffect(() => {
    if (!cardRef.current) {
      return;
    }

    if (question.cards.length > cards.length) {
      cardRef.current.style.cursor = 'pointer';
      cardRef.current.addEventListener('mousedown', mouseDown);
      cardRef.current.addEventListener('touchstart', mouseDown, {
        passive: false,
      });
    }
  }, [question.cards, cards, mouseDown]);

  useEffect(() => {
    if (selectedCardDirection != null) {
      setSelectedCards(oldSelectedCards => [
        ...oldSelectedCards,
        { id: cards[0].id, side: selectedCardDirection },
      ]);
      setSelectedCardDirection(null);
    }
  }, [selectedCardDirection, cards]);

  // submit response when all cards are selected
  useEffect(() => {
    if (question.cards.length === selectedCards.length && !submitResponse) {
      submitSwipeAnswer(question.id, selectedCards);
      setSubmitResponse(true);
    }
  }, [
    question.cards.length,
    question.id,
    selectedCards,
    submitResponse,
    submitSwipeAnswer,
  ]);

  const swipeLeft = () => {
    if (
      !cardRef.current ||
      !cardSwipePropertiesRef.current ||
      !gradientOverlayRef.current ||
      !cardAnswerTextRowDivRef.current
    ) {
      return;
    }

    cardSwipePropertiesRef.current.isCardMoving = true;
    setCardSwipeDirection(SwipeQuestionOptionSide.LEFT);

    const { leftColorRgbArray } = cardSwipePropertiesRef.current;
    moveCardLeft(
      cardRef.current,
      gradientOverlayRef.current,
      cardAnswerTextRowDivRef.current,
      leftColorRgbArray,
    );
    setSelectedCardDirection(SwipeQuestionOptionSide.LEFT);
    cardTransitionEnd();
  };

  const swipeRight = () => {
    if (
      !cardRef.current ||
      !cardSwipePropertiesRef.current ||
      !gradientOverlayRef.current ||
      !cardAnswerTextRowDivRef.current
    ) {
      return;
    }

    cardSwipePropertiesRef.current.isCardMoving = true;
    setCardSwipeDirection(SwipeQuestionOptionSide.RIGHT);

    const { rightColorRgbArray } = cardSwipePropertiesRef.current;
    moveCardRight(
      cardRef.current,
      gradientOverlayRef.current,
      cardAnswerTextRowDivRef.current,
      rightColorRgbArray,
    );
    setSelectedCardDirection(SwipeQuestionOptionSide.RIGHT);
    cardTransitionEnd();
  };

  const swipeTutorialAnimation = (
    <div className={`${!showSwipeTutorial ? styles.hidden : ''}`}>
      <div className={`${showSwipeTutorial ? styles.swipeTutorial : ''}`}>
        <span>{swipeOptions.right.text}</span>
        <span>{swipeOptions.left.text}</span>
      </div>
      <div className={styles.thumb}></div>
    </div>
  );

  const allCardsSwipedContent = (
    <div className={styles.allCardsSwipedContainer}>
      <Airplane />
      <span className={styles.cardsSwipedText1}>Your answers are sent!</span>
      <span className={styles.cardsSwipedText2}>Wait for results...</span>
    </div>
  );

  return (
    <>
      {showSwipeTutorial && (
        <div
          className={styles.overlay}
          data-testid="swipeTutorialOverlay"
        ></div>
      )}
      <div className={styles.container}>
        {cards.length > 0 ? (
          <>
            <QuestionHeader question={question} />
            <ProgressBar />

            <div className={styles.cardsAndSwipeOptionsContainer}>
              <div
                className={`${styles.leftSwipeBtnContainer} ${
                  cardSwipeDirection != null ? styles.hidden : styles.visible
                }`}
              >
                <SwipeBtnDesktop
                  btnText={swipeOptions.left.text}
                  svgComponent={<SwipeLeftSvg width={23} height={24} />}
                  handleClick={swipeLeft}
                  ariaLabel="Swipe left button"
                  customBtnClass={styles.leftSwipeBtn}
                />
              </div>

              <SwipeLabelMobile
                leftText={swipeOptions.left.text}
                rightText={swipeOptions.right.text}
              />

              <div className={styles.cards}>
                {swipeTutorialAnimation}

                {cards.map((card, idx) => (
                  <div
                    key={card.id}
                    className={`${styles.card} ${
                      !card.image?.url ? styles.textCardStyles : ''
                    }`}
                    ref={idx === 0 ? cardRef : null}
                    data-testid="card"
                  >
                    {card.image?.url ? (
                      <ImageCard
                        idx={idx}
                        cardTitle={card.title}
                        cardImageUrl={card.image.url}
                        leftText={swipeOptions.left.text}
                        rightText={swipeOptions.right.text}
                        cardSwipeDirection={cardSwipeDirection}
                        blurClassName={getBlurClassNameForImageCard(idx)}
                        gradientOverlayRef={
                          idx === 0 ? gradientOverlayRef : null
                        }
                        cardAnswerTextRowRef={
                          idx === 0 ? cardAnswerTextRowDivRef : null
                        }
                      />
                    ) : (
                      <TextCard
                        idx={idx}
                        cardTitle={card.title}
                        leftText={swipeOptions.left.text}
                        rightText={swipeOptions.right.text}
                        cardSwipeDirection={cardSwipeDirection}
                        gradientOverlayRef={
                          idx === 0 ? gradientOverlayRef : null
                        }
                        cardAnswerTextRowRef={
                          idx === 0 ? cardAnswerTextRowDivRef : null
                        }
                      />
                    )}
                  </div>
                ))}
              </div>
              <div
                className={`${styles.rightSwipeBtnContainer} ${
                  cardSwipeDirection != null ? styles.hidden : styles.visible
                }`}
              >
                <SwipeBtnDesktop
                  btnText={swipeOptions.right.text}
                  svgComponent={<SwipeRightSvg width={23} height={24} />}
                  handleClick={swipeRight}
                  ariaLabel="Swipe right button"
                  customBtnClass={styles.rightSwipeBtn}
                />
              </div>
            </div>
          </>
        ) : (
          allCardsSwipedContent
        )}
      </div>
    </>
  );
};

const getClientX = (e: MouseEvent | TouchEvent) => {
  if (e.type.includes('mouse')) {
    return (e as MouseEvent).clientX;
  }

  return (e as TouchEvent).touches[0].clientX;
};

const getClientY = (e: MouseEvent | TouchEvent) => {
  if (e.type.includes('mouse')) {
    return (e as MouseEvent).clientY;
  }

  return (e as TouchEvent).touches[0].clientY;
};

const hexToRgbArray = (hex: string) => {
  // Remove the leading # if it's present
  hex = hex.replace(/^#/, '');

  // If it's a three-character hex code, convert it to six characters
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(c => c + c)
      .join('');
  }

  // Parse the r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return [r, g, b];
};

const applyCardMoveEffect = (
  cardDiv: HTMLDivElement,
  gradientOverlayDiv: HTMLDivElement,
  cardAnswerTextRowDiv: HTMLDivElement,
  moveEffect: CardMoveEffect,
) => {
  const {
    rotationDeg,
    translate,
    gradientDeg,
    transition,
    progress,
    rgbArray,
  } = moveEffect;

  cardDiv.style.transition = transition;
  cardDiv.style.transform = `translate(${translate[0]}px, ${translate[1]}px) rotate(${rotationDeg}deg)`;

  gradientOverlayDiv.style.transition =
    transition === 'none' ? 'none' : 'background .7s ease';

  if (progress === 0) {
    gradientOverlayDiv.style.background = 'none';
    return;
  }

  gradientOverlayDiv.style.background = `linear-gradient(
    ${gradientDeg}deg,
    rgba(${rgbArray.join(', ')}, ${progress}) 3%,
    rgba(${rgbArray.join(', ')}, ${progress}) 97%
  )`;

  cardAnswerTextRowDiv.style.opacity = `${progress}`;
};

const moveCardLeft = (
  cardDiv: HTMLDivElement,
  gradientOverlayDiv: HTMLDivElement,
  cardAnswerTextRowDiv: HTMLDivElement,
  rgbArray: number[],
) => {
  const transition = 'transform .7s ease, background .7s ease';

  applyCardMoveEffect(cardDiv, gradientOverlayDiv, cardAnswerTextRowDiv, {
    rotationDeg: 50,
    translate: [-1000, 0],
    gradientDeg: LEFT_GRADIENT_DEG,
    transition,
    rgbArray,
    progress: 1,
  });
};

const moveCardRight = (
  cardDiv: HTMLDivElement,
  gradientOverlayDiv: HTMLDivElement,
  cardAnswerTextRowDiv: HTMLDivElement,
  rgbArray: number[],
) => {
  const transition = 'transform .7s ease, background .7s ease';

  applyCardMoveEffect(cardDiv, gradientOverlayDiv, cardAnswerTextRowDiv, {
    rotationDeg: -50,
    translate: [1000, 0],
    gradientDeg: RIGHT_GRADIENT_DEG,
    transition,
    rgbArray,
    progress: 1,
  });
};

const getBlurClassNameForImageCard = (idx: number) => {
  if (idx === 1) {
    return styles.blurEffectSecondCard;
  } else if (idx === 2) {
    return styles.blurEffectThirdCard;
  } else {
    return '';
  }
};
