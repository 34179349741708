import * as React from 'react';
import styles from './VotingModel.module.css';
import { IComment } from '../../../models/Comment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  useSubmitCommentVotes,
  useTakeLivePollState,
} from '../../../contexts/takeLivePollStateContext';
import { LivePollSessionState } from '../../../models/LivePollSessionState';
import likeIcon from '../../../icons/Like.svg';
import darkLikeicon from '../../../icons/DarkLike.svg';

import disLikeIcon from '../../../icons/Dislike.svg';
import darkDisLikeIcon from '../../../icons/DarkDislike.svg';

interface Props {
  showUpvoteAndDownVoteCount: boolean;
  comment: IComment;
}

export const VotingModel = ({ showUpvoteAndDownVoteCount, comment }: Props) => {
  const submitCommentVotes = useSubmitCommentVotes();
  const { serverState } = useTakeLivePollState();
  const [isSubmittingVotes, setIsSubmittingVotes] =
    React.useState<boolean>(false);

  const submitVotes = async (isUpvote: boolean) => {
    if (
      serverState.livePollSession?.state ===
      LivePollSessionState.QUESTION_STARTED
    ) {
      setIsSubmittingVotes(true);
      await submitCommentVotes(comment.id, isUpvote);
      setIsSubmittingVotes(false);
    }
  };

  const votedComment =
    serverState.liveFeedVotes && serverState.liveFeedVotes[comment.id];

  return (
    <div className={styles.container}>
      {!isSubmittingVotes && (
        <>
          <img
            src={votedComment?.isUpVote ? darkLikeicon : likeIcon}
            className={styles.icon}
            alt="like icon"
            onClick={() => submitVotes(true)}
          />
          <span className={styles.upVoteCount} data-testid="upVoteCount">
            {showUpvoteAndDownVoteCount ? comment.upvoteCount : ``}
          </span>

          <img
            className={styles.icon}
            src={votedComment?.isDownVote ? darkDisLikeIcon : disLikeIcon}
            alt="dislike icon"
            onClick={() => submitVotes(false)}
          />

          <span className={styles.downVoteCount} data-testid="downVoteCount">
            {showUpvoteAndDownVoteCount ? comment.downvoteCount : ``}
          </span>
        </>
      )}
      {isSubmittingVotes && (
        <FontAwesomeIcon
          icon={faSpinner}
          className={`fa-spin ${styles.spinner}`}
        />
      )}
    </div>
  );
};
