import { SwipeLeftSvg } from '../../../../../components/svgImages/swipeLeftSvg';
import { SwipeRightSvg } from '../../../../../components/svgImages/swipeRightSvg';
import styles from './SwipeLabelMobile.module.scss';

interface Props {
  leftText: string;
  rightText: string;
}

export const SwipeLabelMobile = ({ leftText, rightText }: Props) => {
  return (
    <div className={styles.cardSwipeBtnMobile}>
      <div className={styles.leftSide}>
        <div className={styles.iconContainer}>
          <SwipeLeftSvg width={16} height={18} />
        </div>
        <span className={styles.leftSwipeBtnTextMobile}>{leftText}</span>
      </div>
      <div className={styles.rightSide}>
        <span className={styles.rightSwipeBtnTextMobile}>{rightText}</span>
        <div className={styles.iconContainer}>
          <SwipeRightSvg width={16} height={18} />
        </div>
      </div>
    </div>
  );
};
