import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from '../../../components/button/Button';
import { InputEmail } from '../../../components/input-email/InputEmail';
import { Leaderboard } from '../../../components/leaderboard/Leaderboard';
import { LivePollFinishedReport } from '../../../models/LivePollFinishedReport';
import { LivePollType } from '../../../models/LivePollType';
import { QuizStats } from './QuizStats';
import styles from './SendMyResults.module.scss';

interface Props {
  report: LivePollFinishedReport;
  onSendMyResultsClick: (emailAddress: string) => void;
  userIsTyping: () => void;
  clickOnScroll: () => void;
}

export const SendMyResults = ({
  report,
  onSendMyResultsClick,
  userIsTyping,
  clickOnScroll,
}: Props) => {
  const teamRank = report?.teamRank || 0;
  const rank = report?.rank || 0;
  const livePollType = report?.livePollType;
  const correctAnswerCount = report?.correctAnswerCount;
  const totalQuestionCount = report?.totalQuestionCount;
  const speedRank = report?.speedRank || 0;
  const leaderboard = report?.leaderboard;

  const submitButtonLabel = `Send me results`;

  const [email, setEmail] = React.useState<string>('');
  const [shakeInput, setShakeInput] = React.useState<boolean>(false);
  const [placeholder, setPlaceholder] = React.useState<string>('Enter Email');
  const [userStartedTyping, setUserStartedTyping] = useState<boolean>(false);

  const handleFormSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!email || !validateEmail(email)) {
      setEmail('');
      setPlaceholder('Invalid email address');
      setShakeInput(true);
    } else {
      onSendMyResultsClick(email);
    }
  };

  const handleEmailUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onKeyPress = () => {
    if (!userStartedTyping) {
      setUserStartedTyping(true);
      userIsTyping();
    }
  };

  React.useEffect(() => {
    if (!shakeInput) {
      return;
    }
    let timer = setTimeout(() => {
      setShakeInput(false);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [shakeInput]);

  return (
    <div className={styles.container}>
      {livePollType === LivePollType.QUIZ && (
        <QuizStats
          rank={rank}
          teamRank={teamRank}
          correctAnswerCount={correctAnswerCount!}
          totalQuestionCount={totalQuestionCount!}
          speedRank={speedRank}
        />
      )}

      {leaderboard && (
        <div className={styles.leaderboard}>
          <Leaderboard
            leaderboard={leaderboard}
            respondentId={report.respondentId}
            previousRank={0}
            rank={rank}
          />
        </div>
      )}
      <form onSubmit={handleFormSubmit} className={styles.form}>
        <div className={shakeInput ? styles.shakeInput : ''}>
          <InputEmail
            text={email}
            onChange={handleEmailUpdate}
            placeholder={placeholder}
            onKeyPress={onKeyPress}
          />
        </div>

        <Button label={submitButtonLabel} />
      </form>
      <div className={styles.mostPopularTemplate} onClick={clickOnScroll}>
        Most popular templates
        <div className={styles.downArrow}>
          <FontAwesomeIcon icon={faArrowDown} />
        </div>
      </div>
    </div>
  );
};

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
