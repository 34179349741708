export const LivefeedSvg = () => {
  return (
    <svg
      width="17.9987341px"
      height="18.010135px"
      viewBox="0 0 17.9987341 18.010135"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Livefeed</title>
      <g
        id="Livefeed"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M17.9987341,2.9404302 L17.9987341,13.4918393 L14.986701,13.4918393 L14.986701,18.010135 L9.44230119,13.4918393 L2.93856883,13.4918393 L2.93856883,9.72347888 L4.44458535,9.72347888 L4.44458535,11.9844951 L9.98826978,11.9844951 L13.4806845,14.7911699 L13.4806845,11.9844951 L16.4927175,11.9844951 L16.4927175,4.44777435 L9.71564319,4.44777435 L9.71564319,2.9404302 L17.9987341,2.9404302 Z M8.08106428,6.61596796 C8.89252765,6.61596796 9.55034869,7.27420568 9.55034869,8.08618306 C9.55034869,8.89816044 8.89252765,9.55639816 8.08106428,9.55639816 C7.2696009,9.55639816 6.61177986,8.89816044 6.61177986,8.08618306 C6.61177986,7.27420568 7.2696009,6.61596796 8.08106428,6.61596796 Z M8.08106428,2.9404302 L8.08106428,4.41064531 C6.10579159,4.41064531 4.49467464,5.97076899 4.41124693,7.92674586 L4.40785324,8.08618306 L2.93856883,8.08618306 C2.93856883,5.30346892 5.14600952,3.03665675 7.90427281,2.94341402 L8.08106428,2.9404302 Z M8.08106428,0 L8.08106428,1.4702151 C4.49468597,1.4702151 1.57522612,4.32744118 1.47210331,7.89111327 L1.46928441,8.08618306 L0,8.08618306 C0,3.69119439 3.50406964,0.115143439 7.86918797,0.00272534113 L8.08106428,0 Z"
          id="Shape"
          fill="currentColor"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};
