import React, { useState } from 'react';
import { Button } from '../../../components/button/Button';
import { Spinner } from '../../../components/spinner/Spinner';
import pollIcon from '../../../images/poll.svg';
import quizIcon from '../../../images/quiz.svg';
import { ITemplate } from '../../../models/Template';
import { TemplateType } from '../../../models/TemplateType';
import { getTemplateTitle } from '../../../utils/getTemplateTitle';
import { getApi } from '../../utils/apiUtil';
import styles from './ThankYou.module.scss';

interface Props {
  emailAddress: string;
  displayPreviewScreen(templateId: number): void;
}

const SOURCE_REF = 'livepoll-thankyoupage';

export const ThankYou = ({ emailAddress, displayPreviewScreen }: Props) => {
  const [templates, setTemplates] = useState<ITemplate[] | undefined>(
    undefined,
  );
  const [error, setError] = useState<boolean>(false);

  React.useMemo(async () => {
    try {
      const { data } = await getApi(`/take/templates/most-popular`);
      setTemplates(data);
    } catch (err) {
      setError(true);
    }
  }, []);

  const openTemplatePreview = (templateId: number) => {
    displayPreviewScreen(templateId);
  };

  if (error) {
    return <div>Error While Fetching most popular templates</div>;
  }

  if (!templates) {
    return <Spinner message="Loading top templates" />;
  }

  const templatesArray = templates.map(template => {
    return (
      <div
        className={styles.template}
        key={template.templateId}
        onClick={() => openTemplatePreview(template.id)}
      >
        <div className={styles.title}>
          {getTemplateTitle(template.type, template.title)}
        </div>
        <div className={styles.metaDescription}>{template.shortDesc}</div>
        <div className={styles.templateFooter}>
          <div className={styles.templateType}>
            {getTemplateCategoryAndIcon(template.type)}
          </div>
          <div className={styles.questionCount}>
            {template.questionCount} Questions
          </div>
        </div>
      </div>
    );
  });

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setTimeout(() => {
      document.location.href =
        'https://www.questionpro.com/a/showEntry.do?mode=LivePolls&' +
        `sourceRef=${SOURCE_REF}&email=${encodeURIComponent(emailAddress)}`;
    }, 200);
  };

  return (
    <>
      {emailAddress && (
        <>
          <div className={styles.linkToResultsText}>
            You will receive a link to the results dashboard in short.
          </div>
        </>
      )}

      <div className={styles.topTemplatesText}>Top LivePoll Templates</div>

      <div className={styles.templatesBlock}>{templatesArray}</div>
      <div className={styles.bottom}>
        <form onSubmit={handleFormSubmit} className={styles.form}>
          <div className={styles.signUpText}>
            Want to create your own LivePoll?
          </div>
          <Button label="Sign up" />
        </form>
      </div>
    </>
  );
};

const getTemplateCategoryAndIcon = (category: TemplateType) => {
  if (category === TemplateType.QUIZ) {
    return (
      <div className={styles.quizIconRow}>
        <img
          src={quizIcon}
          alt="quiz"
          className={styles.templateTypeIconQuiz}
        />
        Quiz
      </div>
    );
  }
  return (
    <div>
      <img src={pollIcon} alt="poll" className={styles.templateTypeIconPoll} />
      Poll
    </div>
  );
};
