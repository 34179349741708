import { Button } from '../../../components/button/Button';
import { WelcomeMessage } from './WelcomeMessage';
import styles from './StartProfileQuestionScreen.module.css';
import { ProfileQuestionStartedScreen } from '../../profile-question-started/ProfileQuestionStartedScreen';
import { useEffect, useState } from 'react';
import {
  useChangeRespondentTeam,
  useTakeLivePollState,
} from '../../../contexts/takeLivePollStateContext';
import { TeamSelectionDropDown } from './TeamSelectionDropDown';
import { Team } from '../../../models/Team';
import { SelectedTeamDetails } from './SelectedTeamDetails';

interface Props {
  title: string;
}
export const StartProfileQuestions = ({ title }: Props) => {
  const [showProfileQuestions, setShowProfileQuestions] =
    useState<boolean>(false);
  const { serverState } = useTakeLivePollState();
  const { respondent } = serverState;
  const profileQuestionsAnsweredCount =
    serverState.profileQuestionsAnsweredCount;
  const showTeam = !!respondent.teamUid;

  const changeTeam = useChangeRespondentTeam();

  useEffect(() => {
    if (profileQuestionsAnsweredCount && profileQuestionsAnsweredCount > 0) {
      setShowProfileQuestions(true);
    }
  }, [profileQuestionsAnsweredCount]);

  const handleStartProfileQuestion = () => {
    setShowProfileQuestions(true);
  };

  if (showProfileQuestions) {
    return <ProfileQuestionStartedScreen />;
  }

  const handleChangeRespondentTeam = (team: Team) => {
    const teamUid = team.id!;
    changeTeam(teamUid);
  };

  return (
    <div>
      <WelcomeMessage title={title} />
      {showTeam && (
        <div className={styles.showTeam}>
          <SelectedTeamDetails />
          <TeamSelectionDropDown
            changeRespondentTeam={team => handleChangeRespondentTeam(team)}
            text="Change team"
          />
        </div>
      )}

      <div
        className={styles.startProfileQuestionText}
        data-testid="profileQuestionText"
      >
        <span>Thanks for joining this LivePoll session!</span>
        <span>Before we begin, we have a few </span>
        <span>questions to get to know you better.</span>
      </div>

      <div
        className={styles.startProfileQuestionBtn}
        onClick={handleStartProfileQuestion}
      >
        <Button label="Start profile questions" />
      </div>
    </div>
  );
};
