import { QuestionDifficultyLevel } from '@livepolls/ui-components/src/enums/question-difficulty-level.enum';
import React, { useEffect, useState } from 'react';
import { ActionButton } from '../../../components/action-button/ActionButton';
import { QuestionHeader } from '../../../components/currentQuestion/QuestionHeader';
import { LoadImage } from '../../../components/load-image/LoadImage';
import ProgressBar from '../../../components/progressbar/ProgressBar.module';
import { IAnswer } from '../../../models/Answer';
import { IChoiceQuestion } from '../../../models/ChoiceQuestion';
import styles from './Answers.module.scss';

interface Props {
  question: IChoiceQuestion;
  onAnswerSelected(answer: IAnswer): () => Promise<void>;
  questionDifficultyLevel?: QuestionDifficultyLevel | null;
}

function letterForIndex(index: number) {
  return String.fromCharCode(97 + index);
}

function orderNumberForIndex(index: number, totalCount: number) {
  if (totalCount > 26) {
    return `${index + 1}) `;
  }

  return `${letterForIndex(index)}) `;
}

function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

export const Answers = ({
  question,
  onAnswerSelected,
  questionDifficultyLevel,
}: Props) => {
  const answers = question.answers;
  const [showImageAnswer, setShowImageAnswer] = useState<boolean>(false);

  const answerBtnDesktopEffects = !isTouchDevice()
    ? styles.answerBtnDesktopEffects
    : '';

  useEffect(() => {
    answers.forEach(answer => {
      if (answer.image?.url && !showImageAnswer) {
        setShowImageAnswer(true);
      }
    });
  }, [answers, showImageAnswer]);

  const trs: React.ReactElement<HTMLTableRowElement>[] = [];
  for (var i = 0; i < answers.length; i += 2) {
    const a1 = answers[i];
    const indexForSecond = i + 1 < answers.length ? i + 1 : null;
    const a2 = indexForSecond != null && answers[indexForSecond];

    const tr = (
      <tr key={i}>
        <td>
          <ActionButton
            className={styles.answerBtn + ' ' + answerBtnDesktopEffects}
            onClick={onAnswerSelected(a1)}
          >
            <div className={styles.answer}>
              <span>{orderNumberForIndex(i, answers.length)}</span> {a1.text}
            </div>
          </ActionButton>
        </td>
        {a2 && (
          <td>
            <ActionButton
              className={styles.answerBtn + ' ' + answerBtnDesktopEffects}
              onClick={onAnswerSelected(a2)}
            >
              <div className={styles.answer}>
                <span>{orderNumberForIndex(i + 1, answers.length)}</span>{' '}
                {a2.text}
              </div>
            </ActionButton>
          </td>
        )}

        {!a2 && <td className={styles.emptyTd} />}
      </tr>
    );

    trs.push(tr);
  }
  let content = (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <tbody>{trs}</tbody>
      </table>
    </div>
  );

  const imageAnswers = answers.map((answer, index) => {
    return (
      <div
        className={styles.answerContainer}
        key={answer.id}
        onClick={onAnswerSelected(answer)}
      >
        <div className={styles.innerAnswerContainer}>
          <div className={styles.answerText}>{answer.text}</div>
          {answer.image?.url && (
            <LoadImage
              key={answer.id}
              imageUrl={answer.image.url}
              imgAltText="answer"
              isOption={true}
            />
          )}
        </div>
      </div>
    );
  });

  if (question.image?.url) {
    content = (
      <div className={styles.questionAnswer}>
        <div className={styles.questionImage}>
          <LoadImage imageUrl={question.image.url} imgAltText="question" />
        </div>
        {showImageAnswer ? (
          <div className={styles.imageAnswerList}>{imageAnswers}</div>
        ) : (
          <table className={styles.table}>
            <tbody>{trs}</tbody>
          </table>
        )}
      </div>
    );
  }

  if (showImageAnswer && !question.image?.url) {
    content = <div className={styles.imageAnswers}>{imageAnswers}</div>;
  }

  // 3 is max no div in row
  const noOfDiv = answers.length % 3;

  for (let i = 0; i < 3 - noOfDiv && noOfDiv !== 0; i++) {
    imageAnswers.push(
      <div
        key={i}
        className={styles.answerContainer}
        style={{ visibility: 'hidden' }}
      ></div>,
    );
  }

  return (
    <div className={styles.container}>
      <ProgressBar />

      <QuestionHeader
        question={question}
        questionDifficultyLevel={questionDifficultyLevel}
      />
      {content}
    </div>
  );
};
