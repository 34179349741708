export const SpeedRankIconInsideCircleSvg = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44ZM23.6956 18.125L25.3611 8L12.4472 21.6821C11.82 22.449 11.857 23.6503 12.5298 24.3652C12.8382 24.6928 13.244 24.875 13.6655 24.875H20.3647L18.6993 35L31.5554 21.3147C32.181 20.5461 32.1416 19.3449 31.4673 18.6318C31.1593 18.306 30.7547 18.125 30.3345 18.125H23.6956Z"
        fill="currentColor"
      />
    </svg>
  );
};
