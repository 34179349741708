import React from 'react';
import {
  RespondentScoreEncoded,
  useTakeLivePollState,
} from '../../../contexts/takeLivePollStateContext';
import styles from './WordGamePartialLeaderBoard.module.scss';
import { ILeaderboardRow } from '../../../models/LeaderboardRow';
import { IRespondent } from '../../../models/Respondent';
import { LeaderboardSvg } from '../../../components/svgImages/leaderboardSvg';

const MAX_LEADERBOARD_LENGTH = 5;

export const WordGamePartialLeaderBoard = () => {
  const { serverState } = useTakeLivePollState();
  const { wordGameLeaderboard, respondent } = serverState;
  const leaderboardRows = transformToLeaderboardRows(wordGameLeaderboard || []);

  const sortedRef = React.useRef<boolean>(false);
  if (!sortedRef.current) {
    sortedRef.current = true;
  }
  const filteredLeaderBoard = filterLeaderBoard(respondent, leaderboardRows);
  return (
    <div className={styles.container}>
      <div className={styles.leaderboardImg}>
        <LeaderboardSvg />
      </div>

      <table className={styles.leaderboard}>
        <tbody>
          {filteredLeaderBoard.map((leaderboardRow, index) => {
            return (
              <tr
                key={index}
                className={
                  respondent.id === leaderboardRow.respondentId
                    ? styles.boldRow
                    : styles.normalRow
                }
                data-testid="leaderboardRow"
              >
                <td></td>
                <td data-testid="rank">
                  <span className={styles.number}>{leaderboardRow.rank}</span>
                </td>
                <td data-testid="respName">{leaderboardRow.respondentName}</td>
                <td>{leaderboardRow.score}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const transformToLeaderboardRows = (
  leaderboard: RespondentScoreEncoded[],
): ILeaderboardRow[] => {
  return leaderboard.map(leaderboardRow => {
    return {
      respondentId: leaderboardRow[0],
      rank: leaderboardRow[1],
      respondentName: leaderboardRow[2],
      score: leaderboardRow[3],
      teamColor: leaderboardRow[4],
    };
  });
};

const filterLeaderBoard = (
  respondent: IRespondent,
  leaderboard: ILeaderboardRow[],
) => {
  if (leaderboard.length <= MAX_LEADERBOARD_LENGTH) {
    return leaderboard;
  }

  const respondentIndex = leaderboard.findIndex(
    resp => resp.respondentId === respondent.id,
  );

  if (respondentIndex <= 0 || respondentIndex === 1) {
    return leaderboard.slice(0, MAX_LEADERBOARD_LENGTH);
  }

  const lastIndex = leaderboard.length - 1;

  if (respondentIndex === lastIndex || respondentIndex === lastIndex - 1) {
    return leaderboard.slice(-MAX_LEADERBOARD_LENGTH);
  }

  return [
    ...leaderboard.slice(respondentIndex - 2, respondentIndex),
    ...leaderboard.slice(respondentIndex, respondentIndex + 3),
  ];
};
