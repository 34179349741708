import styles from './CardAnswerTextRow.module.scss';
import { SwipeQuestionOptionSide } from '../../../../../models/SwipeQuestionOptionSide.enum';
import { forwardRef } from 'react';

interface Props {
  idx: number;
  isImageCard: boolean;
  cardSwipeDirection: null | SwipeQuestionOptionSide;
  rightText: string;
  leftText: string;
  cardAnswerTextRowRef: React.RefObject<HTMLDivElement> | null;
}

export const CardAnswerTextRow = forwardRef<HTMLDivElement, Props>(
  (
    {
      idx,
      isImageCard,
      cardSwipeDirection,
      leftText,
      rightText,
      cardAnswerTextRowRef,
    }: Props,
    ref,
  ) => {
    const applyTransition =
      idx === 0 &&
      cardSwipeDirection !== SwipeQuestionOptionSide.LEFT &&
      cardSwipeDirection !== SwipeQuestionOptionSide.RIGHT;

    return (
      <div
        className={`${styles.cardAnswerTextRow} ${
          isImageCard ? styles.padding : ''
        }`}
        ref={cardAnswerTextRowRef}
      >
        <span
          className={`${applyTransition ? styles.transitionOpacity : ''} ${
            idx !== 0 || cardSwipeDirection !== SwipeQuestionOptionSide.RIGHT
              ? styles.hidden
              : ''
          }`}
        >
          {rightText}
        </span>
        <span
          className={`${applyTransition ? styles.transitionOpacity : ''} ${
            idx !== 0 || cardSwipeDirection !== SwipeQuestionOptionSide.LEFT
              ? styles.hidden
              : ''
          }`}
        >
          {leftText}
        </span>
      </div>
    );
  },
);
