import styles from './Airplane.module.css';
import airplane from '../../../images/airplane.png';
import cloud from '../../../images/cloud.png';

export const Airplane = () => {
  return (
    <div className={styles.container}>
      <div className={styles.overlayCircle}></div>
      <img src={cloud} alt="cloud" className={styles.cloudLeft} />
      <img src={cloud} alt="cloud" className={styles.cloudRight} />
      <img src={airplane} alt="Airplane" className={styles.image} />
    </div>
  );
};
