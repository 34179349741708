import { ExitIconSvg } from '../svgImages/exitIconSvg';
import { removeSessionInfo } from '../util/manageSessionInfo.util';
import styles from './ExitLink.module.scss';

export const ExitLink = () => {
  const showConfirmDialog = (event: React.MouseEvent) => {
    event.stopPropagation();

    const isConfirmed: Boolean = window.confirm(
      'Are you sure you want to leave?',
    );
    if (isConfirmed) {
      removeSessionInfo();
      window.location.pathname = '/';
    }
  };

  return (
    <div onClick={showConfirmDialog}>
      <span className={styles.exitLinkImg} data-testid="Exit">
        <ExitIconSvg />
      </span>
    </div>
  );
};
