import React from 'react';

export function useInterval(callback: CallableFunction, delay: number) {
  const callbackRef = React.useRef<CallableFunction | undefined>();

  // Remember the latest callback.
  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    let id = setInterval(() => {
      callbackRef.current && callbackRef.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}
