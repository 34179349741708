/**
 *
 * @param sentence - string. example - India is a country
 * @returns string, in start case form. example - India Is A Country
 */
export const transformToStartCase = (sentence: string) => {
  const words = sentence.split(' ');
  const startCaseWords = words.map(word => {
    if (word) {
      const initialLetterUpperCased = word[0].toUpperCase();
      const sentenceCaseWord =
        word.length > 1
          ? `${initialLetterUpperCased}${word.substring(1).toLowerCase()}`
          : initialLetterUpperCased;
      return sentenceCaseWord;
    }
    return word;
  });
  return startCaseWords.join(' ');
};
