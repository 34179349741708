import styles from './Button.module.scss';

interface Props {
  label: string;
  disable?: boolean;
}

export const Button = ({ label, disable }: Props) => {
  return (
    <button className={styles.btn} disabled={disable} type="submit">
      {label}
    </button>
  );
};
