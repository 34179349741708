import { useTakeLivePollState } from '../../contexts/takeLivePollStateContext';
import { Spinner } from '../spinner/Spinner';

import styles from './TeamLeaderboard.module.scss';

interface Props {
  respondentTeamUid: string;
}

export const TeamLeaderboard = ({ respondentTeamUid }: Props) => {
  const { serverState } = useTakeLivePollState();
  const leaderboard = serverState.teamLeaderboard;
  const teams = serverState.livePollSession?.setting?.teamSetting?.teams;

  const getTeamName = (teamUid: string) => {
    return teams?.find(team => team.id === teamUid)?.name;
  };

  const getTeamColorCode = (teamUid: string) => {
    return teams?.find(team => team.id === teamUid)?.color;
  };

  if (!leaderboard) {
    return <Spinner />;
  }

  return (
    <table className={styles.leaderboard}>
      <tbody>
        {leaderboard.map((leaderboardRow, index) => {
          return (
            <tr
              key={index}
              className={
                respondentTeamUid === leaderboardRow.teamUid
                  ? styles.boldRow
                  : styles.normalRow
              }
              data-testid="leaderboardRow"
            >
              <td data-testid="rank">
                <span className={styles.number}>{index + 1}</span>
              </td>
              <td
                data-testid="teamName"
                style={{ color: getTeamColorCode(leaderboardRow.teamUid) }}
              >
                Team {getTeamName(leaderboardRow.teamUid)}
              </td>
              <td data-testid="teamScore">{leaderboardRow.teamScore}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
