const shuffle = (word: string) => {
  return word
    .split('')
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join('');
};

const getAllLetterTiles = () =>
  document.querySelectorAll('div[attr-tile-selector]');

const getElementById = (elementId: string): HTMLElement | null => {
  return document.getElementById(elementId);
};

const addTransitionEffect = () => {
  const letterTiles = getAllLetterTiles();
  letterTiles.forEach(letterTile => {
    const div = letterTile as HTMLDivElement;
    div.style.transition = 'left ease-in .2s';
  });
};

const removeTransitionEffect = () => {
  const letterTiles = getAllLetterTiles();
  letterTiles.forEach(letterTile => {
    const div = letterTile as HTMLDivElement;
    div.style.removeProperty('transition');
  });
};

const getScreenWidth = (): number => {
  return window.innerWidth;
};

const isSmallScreen = (): boolean => {
  return getScreenWidth() <= 400;
};

export const leftDistanceInPixels = (index: number) => {
  if (isSmallScreen()) {
    return `${index * 45}px`;
  }
  return `${index * 70}px`;
};

export const getRandomWord = (words: string[], word: string) => {
  let randomWord = '';
  do {
    const randomNumber = Math.floor(Math.random() * words.length);
    randomWord = words[randomNumber].toUpperCase();
  } while (randomWord === word.toUpperCase());

  return randomWord;
};

export const shuffleWord = (word: string) => {
  let shuffledString = '';
  do {
    shuffledString = shuffle(word);
  } while (shuffledString === word);
  return shuffledString;
};

export const resetTilePosition = () => {
  removeTransitionEffect();
  const letterTiles = getAllLetterTiles();
  letterTiles.forEach((letterTile, index) => {
    const div = letterTile as HTMLDivElement;
    div.style.left = leftDistanceInPixels(index);
  });
};

export const shiftLetterTiles = (
  previousLetterId: string,
  currentLetterId: string,
) => {
  addTransitionEffect();
  const previousLetter = getElementById(previousLetterId);
  const currentLetter = getElementById(currentLetterId);
  if (previousLetter && currentLetter) {
    const previousLeft = previousLetter.style.left;
    const currentLeft = currentLetter.style.left;

    previousLetter.style.left = currentLeft;
    currentLetter.style.left = previousLeft;
  }
};

export const addSelectedLetterStyles = (elementId: string) => {
  const element = document.getElementById(elementId)!;
  if (element) {
    element.style.borderRadius = '50%';
    element.style.transform = 'scale(1.5, 1.5)';
  }
};

export const removeSelectedLetterStyles = (elementId: string) => {
  const element = document.getElementById(elementId)!;
  if (element) {
    element.style.borderRadius = '6px';
    element.style.transform = 'scale(1, 1)';
  }
};
