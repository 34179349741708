import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../../components/button/Button';
import { TemplateType } from '../../../models/TemplateType';
import styles from './TemplatePreview.module.css';
import quizIcon from '../../../images/quiz.svg';
import pollIcon from '../../../images/poll.svg';
import { getOrderCharacter } from '../../../utils/getOrderCharacter';
import { useEffect, useRef, useState } from 'react';
import { getApi } from '../../utils/apiUtil';
import React from 'react';
import { IChoiceQuestion } from '../../../models/ChoiceQuestion';
import { AdminAnswer } from '../../../models/AdminAnswer';
import { Spinner } from '../../../components/spinner/Spinner';
import { getTemplateTitle } from '../../../utils/getTemplateTitle';

interface Props {
  templateId: number;
  onBackButtonClick(): void;
}

interface Template {
  id: number;
  questions: IChoiceQuestion[];
  title: string;
  type: TemplateType;
  shortDesc: string;
  uid: string;
}
const DEFAULT_CHAR_CODE = 97;
const QP_TEMPLATE_URL =
  'https://www.questionpro.com/a/showEntry.do?mode=LivePolls&sourceRef=livepolls-template&livePollsTemplateUID=';
const QUESTION_BLOC_DIV_SIZE = 284;

export const TemplatePreview = ({ templateId, onBackButtonClick }: Props) => {
  const questionBlockContainerRef = useRef<HTMLDivElement | null>(null);
  const [template, setTemplate] = useState<Template | undefined>(undefined);
  const [error, setError] = useState<null | Error>();

  React.useMemo(async () => {
    try {
      const { data } = await getApi(`/take/templates/${templateId}`);
      setTemplate(data);
    } catch (err: any) {
      setError(err);
    }
  }, [templateId]);
  const [currentQuestionLeft, setCurrentQuestionLeft] = useState<number>(0);
  const [maxLeft, setMaxLeft] = useState<number>(0);

  useEffect(() => {
    if (template) {
      const totalQuestions = template.questions.length;
      setMaxLeft((totalQuestions - 1) * QUESTION_BLOC_DIV_SIZE * -1);
    }
  }, [template]);

  useEffect(() => {
    if (questionBlockContainerRef.current) {
      questionBlockContainerRef.current.style.left = currentQuestionLeft + 'px';
    }
  }, [currentQuestionLeft]);

  if (error) {
    return <div>Error in getting template</div>;
  }

  if (!template) {
    return <Spinner message="Loading Template" />;
  }

  function loadNextQuestion() {
    setCurrentQuestionLeft(prev => {
      return prev + (prev > maxLeft ? -QUESTION_BLOC_DIV_SIZE : prev * -1);
    });
  }

  function loadPreviousQuestion() {
    setCurrentQuestionLeft(prev => {
      return prev < 0 ? prev + QUESTION_BLOC_DIV_SIZE : maxLeft;
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.backButton} onClick={onBackButtonClick}>
          <FontAwesomeIcon icon={faChevronLeft} className={styles.rightIcon} />
          <span className={styles.backButtonText}>Back</span>
        </div>
        <div className={styles.title}>
          <span>{getTemplateTypeIcon(template.type)}</span>
          <span>{getTemplateType(template.type)}</span>
          <div>{getTemplateTitle(template.type, template.title)}</div>
        </div>
        <div className={styles.description}>{template.shortDesc}</div>

        <div className={styles.questionsWithControls}>
          <div
            className={styles.previous}
            onClick={loadPreviousQuestion}
            data-testid="previousQuestionButton"
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className={styles.previousIcon}
            />
          </div>
          <div className={styles.questionContainer}>
            <div
              className={styles.questionBlockContainer}
              ref={questionBlockContainerRef}
            >
              {template.questions.map((question, index) => (
                <div className={styles.questionBlock} key={question.id}>
                  <div>
                    <span className={styles.currentQuestionIndex}>
                      {index + 1}
                    </span>
                    /{template.questions.length}
                  </div>
                  {question.title}
                  {question.answers.map(
                    (answer: AdminAnswer, index: number) => {
                      return (
                        <div key={answer.id} className={styles.answer}>
                          <span className={styles.characterOrder}>
                            {getOrderCharacter(DEFAULT_CHAR_CODE + index)})
                          </span>
                          {answer.text}
                        </div>
                      );
                    },
                  )}
                </div>
              ))}
            </div>
          </div>
          <div
            className={styles.next}
            onClick={loadNextQuestion}
            data-testid="nextQuestionButton"
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              className={styles.nextIcon}
            />
          </div>
        </div>
        <div className={styles.buttonRow}>
          <a
            href={`${QP_TEMPLATE_URL}${template.uid}`}
            className={styles.useThisTemplate}
          >
            <Button label="Use this template" />
          </a>
        </div>
      </div>
    </div>
  );
};

const getTemplateType = (templateType: TemplateType) => {
  if (templateType === TemplateType.QUIZ) {
    return 'Quiz';
  }

  return 'Poll';
};

const getTemplateTypeIcon = (templateType: TemplateType) => {
  if (templateType === TemplateType.QUIZ) {
    return (
      <img src={quizIcon} alt="quiz" className={styles.templateTypeIconQuiz} />
    );
  }

  return (
    <img src={pollIcon} alt="poll" className={styles.templateTypeIconPoll} />
  );
};
