import * as React from 'react';
import styles from './InputName.module.scss';

interface Props {
  text: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

const NAME_MAX_LENGTH = 128;

export const InputName = ({ text, onChange }: Props) => {
  return (
    <div className={styles.container}>
      <input
        type="text"
        required={true}
        className="fadeIn second"
        placeholder="Enter your name"
        aria-label="Enter your name"
        value={text}
        onChange={onChange}
        maxLength={NAME_MAX_LENGTH}
      />
      <div className={styles.line} />
    </div>
  );
};
