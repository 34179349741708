import styles from './WelcomeMessage.module.scss';

interface Props {
  title: string;
}

export const WelcomeMessage = ({ title }: Props) => {
  return (
    <div className={styles.container}>
      {title && (
        <>
          <div>Welcome to</div>
          <div className={styles.title}>{title}</div>
        </>
      )}
    </div>
  );
};
