interface Props {
  width: number;
  height: number;
}

export const SwipeLeftSvg = ({ width, height }: Props) => (
  <svg
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      d="M18.8077 22.5657C20.0248 22.2392 20.8704 21.1347 20.8681 19.8737L20.8557 14.7915C20.8524 13.3606 19.7625 12.166 18.3371 12.032L14.8605 11.7033C14.167 11.638 13.5871 11.1482 13.4058 10.475L12.5119 7.13908C12.2192 6.04361 11.0932 5.39399 9.99775 5.68783C8.90226 5.98055 8.25262 7.10643 8.54647 8.2019L10.8298 16.7246C10.8658 16.8586 10.7048 16.9565 10.6024 16.8642L9.82324 16.1628C8.60164 15.0628 6.69654 15.2587 5.72378 16.5839L5.63821 16.6998C5.51999 16.8608 5.53237 17.0815 5.66635 17.2279L11.0392 23.0969C11.7384 23.8614 12.8069 24.1733 13.8078 23.9053L18.8077 22.5657Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2759 6.76935C16.2217 0.208851 6.70906 0.13132 2.54762 6.62299C2.27966 7.04181 1.72234 7.16341 1.30352 6.89545C0.884692 6.62637 0.763088 6.07018 1.03105 5.65137C5.90844 -1.95711 17.0571 -1.86603 21.8082 5.82137L22.0008 6.13323C22.262 6.55657 22.1314 7.1116 21.7081 7.37281C21.2847 7.63514 20.7297 7.50342 20.4684 7.0801L20.2759 6.76935Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.46003 6.13789C6.51745 6.63214 6.16393 7.07911 5.66965 7.13768L2.20429 7.54185C1.19886 7.65894 0.299274 6.91024 0.232866 5.90034L0.00205904 2.4192C-0.0317184 1.92268 0.34433 1.49375 0.84086 1.46109C1.33739 1.42732 1.76633 1.80336 1.79899 2.29988L2.02754 5.74845L5.46149 5.34765C5.95462 5.29023 6.40262 5.64362 6.46003 6.13789Z"
      fill="currentColor"
    />
  </svg>
);
