import styles from './Header.module.scss';
import userIconImage from '../../images/User.svg';
import { TeamsUserSvg } from '../svgImages/teamsUserSvg';
import { useTakeLivePollState } from '../../contexts/takeLivePollStateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

interface Props {
  score: number;
  isQuiz: boolean;
  respondentName: string;
  rightLink: React.ReactElement;
  respondentTeamUid?: string;
}

export const Header = ({
  isQuiz,
  score,
  respondentName,
  rightLink,
  respondentTeamUid,
}: Props) => {
  const { serverState } = useTakeLivePollState();
  const { connectedRespondents } = serverState;

  if (respondentTeamUid) {
    const { teamLeaderboard, livePollSession } = serverState;

    const teams = livePollSession?.setting?.teamSetting?.teams;
    const respondentTeam = teams?.find(team => team.id === respondentTeamUid)!;

    const teamScore = teamLeaderboard?.find(
      team => team.teamUid === respondentTeamUid,
    )?.teamScore;

    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <div style={{ color: respondentTeam.color }}>
            <TeamsUserSvg />
          </div>
          <div className={styles.teamRespondentName}>{respondentName}</div>
          <div className={styles.teamOverview}>
            <span data-testid="headerTeamName">
              Team {respondentTeam?.name}
            </span>
            {teamScore && (
              <span className={styles.teamScore} data-testid="headerTeamScore">
                - {teamScore}
              </span>
            )}
          </div>
        </div>
        <div className={styles.right}>{rightLink}</div>
      </div>
    );
  }

  const showConnectedRespondents = !!(
    connectedRespondents && connectedRespondents > 0
  );
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <img src={userIconImage} alt="" className={styles.userIcon} />
        <div className={styles.overview}>
          <span className={styles.name}>{respondentName}</span>
          {isQuiz && <span className={styles.score}>{score || 0} Points</span>}
        </div>
      </div>
      <div className={styles.right}>
        {showConnectedRespondents && (
          <div
            className={styles.connectedRespondents}
            title="Participants count"
          >
            <FontAwesomeIcon icon={faUsers} />
            <div data-testid="connectedRespondents">{connectedRespondents}</div>
          </div>
        )}
        {rightLink}
      </div>
    </div>
  );
};
