import { CorrectInsideCircleSvg } from '../../../components/svgImages/correctInsideCircleSvg';
import { SpeedRankIconInsideCircleSvg } from '../../../components/svgImages/speedRankIconInsideCircleSvg';
import { ordinalSuffix } from '../../../utils/ordinalSuffix';
import { DisplayRankWithImage } from './DisplayRankWithImage';
import styles from './QuizStats.module.css';

interface Props {
  rank: number;
  correctAnswerCount: number;
  totalQuestionCount: number;
  speedRank: number;
  teamRank: number;
}

export const QuizStats = ({
  rank,
  teamRank,
  correctAnswerCount,
  totalQuestionCount,
  speedRank,
}: Props) => {
  const showTeamRank = !!(teamRank > 0);
  return (
    <div className={styles.container}>
      <div className={styles.imageDiv}>
        {!showTeamRank && rank > 0 && (
          <DisplayRankWithImage text="You finished in" rank={rank} />
        )}
        {showTeamRank && (
          <DisplayRankWithImage text="Your team finished in" rank={teamRank} />
        )}
      </div>

      <div className={styles.rightAnswersCountAndSpeedRank}>
        <div className={styles.rightAnswersCount}>
          <CorrectInsideCircleSvg />
          <div className={styles.count} data-testid="correctAnswerCount">
            <span>{correctAnswerCount}</span>/{totalQuestionCount}
            <div className={styles.subText}>Accuracy</div>
          </div>
        </div>
        <div className={styles.speedRankContainer}>
          <SpeedRankIconInsideCircleSvg />
          <div className={styles.count}>
            <span className={styles.speedRank} data-testid="speedRank">
              {ordinalSuffix(speedRank)}
            </span>
            <div className={styles.subText}>Speed</div>
          </div>
        </div>
      </div>
    </div>
  );
};
