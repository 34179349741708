import React, { useState } from 'react';
import { useTakeLivePollState } from '../../../contexts/takeLivePollStateContext';
import styles from './SelectedTeamDetails.module.css';

export const SelectedTeamDetails = () => {
  const { serverState } = useTakeLivePollState();
  const { teamUid } = serverState.respondent;
  const [respondentTeamUid, setRespondentTeamUid] = useState<
    string | undefined
  >(teamUid);

  React.useEffect(() => {
    const { teamUid } = serverState.respondent;
    setRespondentTeamUid(teamUid);
  }, [serverState.respondent]);

  const respondentTeam =
    serverState.livePollSession?.setting?.teamSetting?.teams.find(
      team => team.id === respondentTeamUid,
    );

  return (
    <div className={styles.container}>
      <div>You are now part of</div>
      <div
        className={styles.teamName}
        style={{ background: respondentTeam?.color }}
        data-testid="team"
      >
        <div>{respondentTeam?.name}</div>
      </div>
    </div>
  );
};
