import { useEffect, useState } from 'react';
import { AnswerSubmittedSvg } from '../../components/svgImages/answerSubmittedSvg';
import { useTakeLivePollState } from '../../contexts/takeLivePollStateContext';
import { WaitingRoomScreen } from '../waiting-room/WaitingRoomScreen';
import styles from './ProfileQuestionsFinishedScreen.module.css';

export const ProfileQuestionsFinishedScreen = () => {
  const [showWaitingRoom, setShowWaitingRoom] = useState<boolean>(false);
  const { serverState } = useTakeLivePollState();
  const title = serverState.livePollSession?.name || '';

  useEffect(() => {
    const id = setTimeout(() => {
      setShowWaitingRoom(true);
    }, 5000);
    return () => clearTimeout(id);
  }, []);

  if (showWaitingRoom) {
    return (
      <WaitingRoomScreen
        title={title}
        isProfileQuestionsFinishedScreenDisplayed={true}
      />
    );
  }

  return (
    <div className={styles.container}>
      <span className={styles.image}>{<AnswerSubmittedSvg />}</span>
      <div
        className={styles.textDiv}
        data-testid="profileQuestionsFinishedScreenText"
      >
        <span>Thank you for responding.</span>
        <span>Your host will begin the session in</span>
        <span>short.</span>
      </div>
    </div>
  );
};
