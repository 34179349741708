export const getLightColor = (color: string) => {
  const rgbColor = getRGBValues(color);
  const red = Number(rgbColor.red) + 230;
  const green = Number(rgbColor.green) + 230;
  const blue = Number(rgbColor.blue) + 230;

  return `rgb(${red}, ${green}, ${blue})`;
};

const getRGBValues = (str: string) => {
  var vals = str.substring(str.indexOf('(') + 1, str.length - 1).split(', ');
  return {
    red: vals[0],
    green: vals[1],
    blue: vals[2],
  };
};
