import { Dropdown } from '../../../components/dropdown/dropdown';
import { useTakeLivePollState } from '../../../contexts/takeLivePollStateContext';
import { Team } from '../../../models/Team';
import styles from './TeamSelectionDropDown.module.css';

interface Props {
  changeRespondentTeam(team: Team): void;
  text: string;
}

export const TeamSelectionDropDown = ({
  changeRespondentTeam,
  text,
}: Props) => {
  const { serverState } = useTakeLivePollState();
  const { livePollSession } = serverState;
  const teams = livePollSession?.setting?.teamSetting?.teams;

  if (!teams) return null;

  return (
    <div className={styles.teamInfo}>
      <Dropdown text={text}>
        <ul className={styles.list}>
          {teams?.map((team, index) => {
            return (
              <li
                key={team.name + index}
                className={styles.option}
                onClick={() => changeRespondentTeam(team)}
                data-testid="dropdown-option"
              >
                {team.name}
              </li>
            );
          })}
        </ul>
      </Dropdown>
    </div>
  );
};
