export const removeSessionInfo = () => {
  try {
    window.localStorage.removeItem('sessionInfo');
  } catch (err) {
    console.log(err);
  }
};

export const addSessionInfo = (sessionInfo: {
  pin: string | number;
  respondentId: string;
  livePollSessionId: number;
}) => {
  window.localStorage.setItem('sessionInfo', JSON.stringify(sessionInfo));
};
