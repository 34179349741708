import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

export function AppProviders({ children }: any) {
  return (
    <QueryClientProvider client={new QueryClient()}>
      <Router>{children}</Router>
    </QueryClientProvider>
  );
}
