export const ExitIconSvg = () => {
  const image = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M388,96 L388,98 L383,98 L383,110 L388,110 L388,112 L381,112 L381,96 L388,96 Z M391.983831,97.5 L397,104 L391.983831,110.5 L390.455093,109.23219 L393.709408,105.014171 L386,105.014743 L386,102.985257 L393.709408,102.984685 L390.455093,98.7678096 L391.983831,97.5 Z"
        transform="translate(-381 -96)"
      />
    </svg>
  );

  return image;
};
