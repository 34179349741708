import { useCallback, useEffect, useRef, useState } from 'react';
import { Spinner } from '../spinner/Spinner';
import styles from './LoadImage.module.css';

interface Props {
  imageUrl: string;
  imgAltText: string;
  isOption?: boolean;
}

export const LoadImage = ({ imageUrl, imgAltText, isOption }: Props) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);

  const containerWidth = isOption ? 240 : 366;
  const containerHeight = isOption ? 160 : 244;

  const containerStyles = {
    maxWidth: containerWidth,
    maxHeight: containerHeight,
    margin: isOption ? 'auto' : '',
  };
  const imageStyles = {
    maxWidth: containerWidth,
    maxHeight: containerHeight,
  };

  const resetContainerWidth = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.style.width = `${containerWidth}px`;
      containerRef.current.style.height = `${containerHeight}px`;
    }
  }, [containerWidth, containerHeight]);

  useEffect(() => {
    resetContainerWidth();
    // setIsImageLoading(true);
  }, [imageUrl, resetContainerWidth]);

  const handleImageLoading = () => {
    const checkIsImageLoadedTimeout = setTimeout(() => {
      if (
        imageRef.current &&
        imageRef.current.naturalWidth &&
        imageRef.current.naturalHeight
      ) {
        clearTimeout(checkIsImageLoadedTimeout);
        const naturalWidth = imageRef.current.naturalWidth;
        const naturalHeight = imageRef.current.naturalHeight;
        const width = imageRef.current.width;
        const height = imageRef.current.height;
        if (containerRef.current) {
          if (naturalWidth < width || naturalHeight < height) {
            containerRef.current.style.width = `${naturalWidth}px`;
            containerRef.current.style.height = `${naturalHeight}px`;
          } else {
            containerRef.current.style.width = `${width}px`;
            containerRef.current.style.height = `${height}px`;
          }
        }
      }
    });
    setIsImageLoading(false);
  };

  return (
    <div
      style={containerStyles}
      className={isImageLoading ? styles.darkBackground : ''}
      ref={containerRef}
    >
      <div className={isImageLoading ? styles.visible : styles.hidden}>
        <Spinner />
      </div>
      <img
        src={imageUrl}
        alt={imgAltText}
        ref={imageRef}
        onLoad={handleImageLoading}
        style={imageStyles}
        className={!isImageLoading ? styles.visible : styles.hidden}
      />
    </div>
  );
};
