import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useOnClickOutside } from '../../utils/hooks.util';
import styles from './dropdown.module.css';

interface Props {
  text: string;
  children: React.ReactNode;
  leftIcon?: React.ReactElement;
  leftColor?: string | null;
  customDropdownBtnClass?: string;
}

export const Dropdown = ({
  text,
  leftIcon,
  customDropdownBtnClass,
  leftColor,
  children,
}: Props) => {
  const dropDownRef = React.useRef<HTMLDivElement | null>(null);
  const [isDropDownOpen, setIsDropDownOpen] = React.useState<boolean>(false);

  useOnClickOutside(dropDownRef, () => {
    setIsDropDownOpen(false);
  });

  const handleToggleDropDown = () => {
    setIsDropDownOpen(prevState => !prevState);
  };
  return (
    <div className={styles.dropdown}>
      <button
        className={`${styles.dropDownBtn} ${customDropdownBtnClass}`}
        onClick={handleToggleDropDown}
      >
        {leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
        {leftColor && (
          <span
            className={styles.leftColor}
            style={{ backgroundColor: leftColor }}
          ></span>
        )}
        {text}
        <span className={styles.dropdownIcon}>
          <FontAwesomeIcon icon={isDropDownOpen ? faCaretUp : faCaretDown} />
        </span>
      </button>
      {isDropDownOpen && (
        <div
          className={styles.dropdownContent}
          onClick={handleToggleDropDown}
          ref={dropDownRef}
        >
          {children}
        </div>
      )}
    </div>
  );
};
