export const ArrowDown = () => {
  const image = (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Arrow down</title>
      <g
        id="Respondent-Flow-Mobile"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="R_11-Copy"
          transform="translate(-40.000000, -362.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <path
            d="M48.3080095,374 L43.6919905,374 C43.1821192,374 42.7687868,373.586816 42.7687868,373.077129 L42.7687868,368.462773 L40.4607773,368.462773 C40.2744277,368.461843 40.1069149,368.348995 40.0361035,368.176683 C39.9638182,368.004846 40.0020629,367.806409 40.1330399,367.673719 L45.6722627,362.136492 C45.7589361,362.049136 45.8769196,362 46,362 C46.1230804,362 46.2410639,362.049136 46.3277373,362.136492 L51.8669601,367.673719 C51.9979371,367.806409 52.0361818,368.004846 51.9638965,368.176683 C51.8930851,368.348995 51.7255723,368.461843 51.5392227,368.462773 L49.2312132,368.462773 L49.2312132,373.077129 C49.2312132,373.586816 48.8178808,374 48.3080095,374 Z"
            id="Arrow-down"
            transform="translate(46.000000, 368.000000) scale(1, -1) translate(-46.000000, -368.000000) "
          ></path>
        </g>
      </g>
    </svg>
  );
  return image;
};
