import styles from './DisplayRankWithImage.module.css';
import winnerImage from '../../../images/Winner.svg';
import lostImage from '../../../images/Lost.svg';
import byeImage from '../../../images/Bye.svg';
import { ordinalSuffix } from '../../../utils/ordinalSuffix';

interface Props {
  text: string;
  rank: number;
}
export const DisplayRankWithImage = ({ text, rank }: Props) => {
  return (
    <>
      <img
        alt="rank"
        src={imageByRank(rank)}
        className={styles.rankImage}
        width={150}
        height={120}
      />
      <span className={styles.rankSpan} data-testid="rank">
        {`${ordinalSuffix(rank)}`}
      </span>
    </>
  );
};

function imageByRank(rank: number) {
  if (rank === 1) {
    return winnerImage;
  }

  if (rank > 3) {
    return lostImage;
  }

  return byeImage;
}
