import styles from './SwipeBtnDesktop.module.scss';

interface Props {
  handleClick: () => void;
  btnText: string;
  ariaLabel: string;
  svgComponent: React.ReactNode;
  customBtnClass?: string;
}

export const SwipeBtnDesktop = ({
  btnText,
  handleClick,
  ariaLabel,
  svgComponent,
  customBtnClass,
}: Props) => {
  return (
    <div
      className={styles.cardSwipeBtnContainer}
      onClick={handleClick}
      role="button"
      aria-label={ariaLabel}
    >
      <div className={`${styles.btn} ${customBtnClass || ''}`}>
        {svgComponent}
      </div>
      <span className={styles.swipeOptionText}>{btnText}</span>
    </div>
  );
};
