import styles from './Comment.module.scss';
import { IComment } from '../../../models/Comment';

interface Props {
  comment: IComment;
}

export const Comment = ({ comment }: Props) => {
  return (
    <div className={styles.commentRow}>
      <div className={styles.comment}>{comment.text}</div>
      <div className={styles.respondentName}>- {comment.respondentName}</div>
    </div>
  );
};
