interface Props {
  width: number;
  height: number;
}

export const SwipeRightSvg = ({ width, height }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3603 23.9054C12.5764 24.2316 13.8601 23.6972 14.4865 22.6059L17.0156 18.2014C17.7277 16.9615 17.3812 15.3831 16.2157 14.554L13.3705 12.5334C12.8035 12.1295 12.5459 11.4162 12.7259 10.7434L13.6192 7.40999C13.9128 6.31531 13.2637 5.19027 12.169 4.89774C11.0744 4.60411 9.94936 5.25326 9.65572 6.34794L7.37418 14.8645C7.33818 14.9984 7.1503 15.0029 7.10755 14.8712L6.78355 13.8756C6.27617 12.3129 4.53015 11.531 3.02596 12.1914L2.89433 12.2499C2.71208 12.3297 2.61195 12.5266 2.65471 12.7212L4.37147 20.4829C4.59534 21.4954 5.36373 22.2987 6.36388 22.5665L11.3603 23.9054Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.517 1.93974C22.0109 1.99712 22.3653 2.44264 22.3079 2.93652L21.9074 6.40045C21.7915 7.40512 20.8578 8.10828 19.8599 7.94178L16.4208 7.36912C15.9303 7.28812 15.5995 6.82461 15.6805 6.33406C15.7627 5.84355 16.2262 5.51277 16.7167 5.59379L20.1232 6.16082L20.5203 2.7306C20.5766 2.23671 21.0231 1.88237 21.517 1.93974Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.323966 5.72617C4.99625 -1.83531 15.9617 -1.92517 20.7587 5.55854C21.0276 5.97705 20.905 6.53395 20.4865 6.80172C20.068 7.07061 19.5111 6.94798 19.2433 6.52946C15.1617 0.161791 5.83097 0.238114 1.85508 6.67347L1.66608 6.97948C1.40395 7.40136 0.849326 7.53301 0.427429 7.27198C0.00440873 7.00985 -0.127209 6.45521 0.134932 6.03218L0.323966 5.72617Z"
      fill="currentColor"
    />
  </svg>
);
