import { RespondentScoreEncoded } from '../../contexts/takeLivePollStateContext';
import { ILeaderboardRow } from '../../models/LeaderboardRow';
import { ArrowDown } from '../svgImages/arrowDownSvg';
import { ArrowUp } from '../svgImages/arrowUpSvg';
import styles from './Leaderboard.module.scss';
interface Props {
  leaderboard: RespondentScoreEncoded[];
  respondentId: number;
  previousRank: number;
  rank: number;
}

const transformToLeaderboardRows = (leaderboard: RespondentScoreEncoded[]) => {
  const leaderboardRows: ILeaderboardRow[] = [];
  leaderboard.forEach(leaderboardRow => {
    const data: ILeaderboardRow = {
      respondentId: leaderboardRow[0],
      rank: leaderboardRow[1],
      respondentName: leaderboardRow[2],
      score: leaderboardRow[3],
      teamColor: leaderboardRow[4],
    };
    leaderboardRows.push(data);
  });
  return leaderboardRows;
};

export const Leaderboard = ({
  leaderboard,
  respondentId,
  previousRank,
  rank,
}: Props) => {
  const leaderboardRows = transformToLeaderboardRows(leaderboard);
  const getUpOrDownArrow = (previousRank: number, rank: number) => {
    if (previousRank > 0 && rank > 0 && previousRank > rank) {
      return (
        <span className={styles.arrowUp}>
          <ArrowUp />
        </span>
      );
    } else if (previousRank > 0 && rank > 0 && previousRank < rank) {
      return (
        <span className={styles.arrowDown}>
          <ArrowDown />
        </span>
      );
    }
  };

  return (
    <table className={styles.leaderboard}>
      <tbody>
        {leaderboardRows.map((leaderboardRow, index) => {
          return (
            <tr
              key={index}
              className={
                respondentId === leaderboardRow.respondentId
                  ? styles.boldRow
                  : styles.normalRow
              }
              data-testid="leaderboardRow"
            >
              <td>
                {respondentId === leaderboardRow.respondentId &&
                  getUpOrDownArrow(previousRank, rank)}
              </td>
              <td data-testid="rank">
                <span className={styles.number}>{leaderboardRow.rank}</span>
              </td>
              <td>
                {leaderboardRow.teamColor && (
                  <div
                    className={styles.team}
                    style={{ background: leaderboardRow.teamColor }}
                    data-testid="teamColor"
                  ></div>
                )}
              </td>

              <td data-testid="respName">{leaderboardRow.respondentName}</td>
              <td data-testid="respScore">{leaderboardRow.score}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
