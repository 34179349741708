interface Props {
  color: string;
}

export const WordCloudAnswerSvg = ({ color }: Props) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5264 0.994141C14.7108 0.994141 15.6709 1.95428 15.6709 3.13867V11.7168C15.6709 12.9012 14.7108 13.8613 13.5264 13.8613H5.4545C5.12157 13.8613 4.79321 13.9388 4.49543 14.0877L0.65918 16.0059V3.13867C0.65918 1.95428 1.61932 0.994141 2.80371 0.994141H13.5264Z"
        fill={color}
      />
    </svg>
  );
};
