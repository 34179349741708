import * as React from 'react';
import styles from './InputPin.module.scss';

interface Props {
  pin: string[];
  onChange(newPin: string[]): void;
}

interface InputBoxProps {
  id: string;
  value: string;
  ariaLabel: string;
  autoFocus?: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onKeyUp(event: React.KeyboardEvent<HTMLInputElement>): void;
  onClick(): void;
  onPaste(e: React.ClipboardEvent<HTMLInputElement>): void;
}

function isNumeric(value: string) {
  return /^\d+$/.test(value);
}

const InputBox = ({
  id,
  value,
  ariaLabel,
  autoFocus,
  onChange,
  onKeyUp,
  onClick,
  onPaste,
}: InputBoxProps) => {
  return (
    <input
      id={id}
      type="text"
      required={true}
      placeholder="-"
      value={value}
      aria-label={ariaLabel}
      onChange={onChange}
      maxLength={1}
      onKeyUp={onKeyUp}
      autoFocus={autoFocus}
      pattern="[0-9]*"
      inputMode="numeric"
      onClick={onClick}
      onPaste={onPaste}
      autoComplete="off"
    />
  );
};

export const InputPin = ({ pin, onChange }: Props) => {
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    try {
      const pastedText = e.clipboardData.getData('text');
      if (pastedText == null) {
        return;
      }

      const trimmedPastedText = pastedText.trim();
      if (
        trimmedPastedText.trim().length < 4 ||
        !isNumeric(trimmedPastedText)
      ) {
        return;
      }

      onChange(trimmedPastedText.split(''));

      const lastPinBox = document.getElementById(`inputPin${3}`);
      lastPinBox && lastPinBox.focus();
    } catch (e) {
      // ignore, all browsers that don't support copy paste detection
    }
  };

  const focusFirstBoxIfPinEmpty = () => {
    const areAllBoxesEmpty = pin.filter(p => p === '').length === 4;
    const firstBox = document.getElementById('inputPin0');
    if (areAllBoxesEmpty && firstBox) {
      firstBox.focus();
    }
  };

  const handlePinChange = (index: number) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (isNumeric(value) || value === '') {
        const newPin = [...pin];
        newPin[index] = value;
        onChange(newPin);
      }

      if (isNumeric(value) && event != null) {
        // @ts-ignore
        let target: any = event.srcElement || event.target;
        const nextElement = target.nextElementSibling;
        if (nextElement && nextElement.tagName.toLowerCase() === 'input') {
          nextElement.focus();
        }
      }
    };
  };

  const handleBackspace = (index: number) => {
    return (event: React.KeyboardEvent<HTMLInputElement>) => {
      const currentValue = pin[index];
      if (
        event.keyCode === 8 &&
        currentValue != null &&
        currentValue.length === 0
      ) {
        // @ts-ignore
        let target = event.srcElement || event.target;
        const prevElement = target.previousElementSibling;
        if (prevElement && prevElement.tagName.toLowerCase() === 'input') {
          prevElement.focus();
          if (prevElement.select) {
            prevElement.select();
          }
        }
      }
    };
  };

  const renderInputBox = (index: number) => {
    return (
      <InputBox
        id={`pinBox${index}`}
        value={pin[index]}
        autoFocus={index === 0}
        ariaLabel="Enter Pin"
        onChange={handlePinChange(index)}
        onKeyUp={handleBackspace(index)}
        onClick={focusFirstBoxIfPinEmpty}
        onPaste={handlePaste}
      />
    );
  };

  return (
    <div className={styles.pinInput}>
      {renderInputBox(0)}
      {renderInputBox(1)}
      {renderInputBox(2)}
      {renderInputBox(3)}
    </div>
  );
};
