import styles from './CompanyLogo.module.css';
export const CompanyLogo = () => {
  const logo = decodeURIComponent(
    document.documentElement.style.getPropertyValue('--logo'),
  );

  return (
    <div className={styles.container}>
      {logo && (
        <img
          src={logo}
          alt="company logo"
          height="30px"
          className={styles.logoImg}
        />
      )}
    </div>
  );
};
