import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { LivefeedSvg } from '../../../components/svgImages/liveFeedSvg';
import {
  useError,
  useTakeLivePollState,
} from '../../../contexts/takeLivePollStateContext';
import { IComment } from '../../../models/Comment';
import { LivePollSessionState } from '../../../models/LivePollSessionState';
import { Comment } from './Comment';
import styles from './DisplayComments.module.scss';
import { LiveFeedQuestionStartedTabs } from './LiveFeedQuestionStartedTabs.enum';
import { VotingModel } from './VotingModel';

export const DisplayComments = () => {
  const [tabOne, setTabOne] = React.useState<boolean>(true);
  const [tabTwo, setTabTwo] = React.useState<boolean>(false);
  const { serverState } = useTakeLivePollState();
  const error = useError();

  const handleTabChange = (tabNumber: number) => {
    if (tabNumber === LiveFeedQuestionStartedTabs.COMMENT_TAB) {
      setTabOne(true);
      setTabTwo(false);
    } else if (tabNumber === LiveFeedQuestionStartedTabs.TOP_VOTED_TAB) {
      setTabOne(false);
      setTabTwo(true);
    }
  };

  const renderComments = (tabNumber: number) => {
    let comments = serverState.liveFeedQuestionResponse?.comments! || [];

    if (tabNumber === LiveFeedQuestionStartedTabs.TOP_VOTED_TAB) {
      const sortedComments = [...comments];
      sortedComments.sort(function (comment1, comment2) {
        return comment2.upvoteCount - comment1.upvoteCount;
      });
      comments = sortedComments;
    }

    return comments.map((comment: IComment, index: number) => {
      return (
        <div key={index}>
          <Comment comment={comment} />
          <VotingModel comment={comment} showUpvoteAndDownVoteCount={true} />
        </div>
      );
    });
  };
  let commentTabTitle = `Don't forget to vote`;

  if (
    serverState.liveFeedVotes &&
    Object.keys(serverState.liveFeedVotes).length > 0
  ) {
    commentTabTitle = `Your votes`;
  }
  return (
    <div className={styles.container}>
      {serverState.livePollSession?.state ===
        LivePollSessionState.QUESTION_FINISHED && (
        <div className={styles.waitingMsg}>Waiting For Next Question...</div>
      )}
      {error && (
        <div className={styles.errorMessage} data-testid="errorMsg">
          {error.message}
        </div>
      )}
      <div className={styles.tabRow}>
        <div className={styles.tab} />
        <div
          className={styles.tabs + ' ' + (tabOne ? styles.activeTab : '')}
          onClick={() =>
            handleTabChange(LiveFeedQuestionStartedTabs.COMMENT_TAB)
          }
        >
          <span className={styles.liveFeedIcon}>
            <LivefeedSvg />
          </span>
          {commentTabTitle}
        </div>

        <div
          className={styles.tabs + ' ' + (tabTwo ? styles.activeTab : '')}
          onClick={() =>
            handleTabChange(LiveFeedQuestionStartedTabs.TOP_VOTED_TAB)
          }
        >
          <FontAwesomeIcon
            icon={faCheckCircle}
            height={20}
            width={20}
            style={{ paddingRight: '8px' }}
          />
          Top Voted
        </div>
      </div>
      {tabOne && (
        <div>{renderComments(LiveFeedQuestionStartedTabs.COMMENT_TAB)}</div>
      )}

      {tabTwo && (
        <div>{renderComments(LiveFeedQuestionStartedTabs.TOP_VOTED_TAB)}</div>
      )}
    </div>
  );
};
