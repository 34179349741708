import { Header } from './components/header/Header';
import {
  useChangeRespondentTeam,
  useTakeLivePollState,
} from './contexts/takeLivePollStateContext';
import { WaitingRoomScreen } from './screens/waiting-room/WaitingRoomScreen';
import styles from './TakeLivePoll.module.scss';
import { ExitLink } from './components/header/ExitLink';
import { LivePollSessionState } from './models/LivePollSessionState';
import { QuestionStartedScreen } from './screens/question-started/QuestionStartedScreen';
import { QuestionFinishedScreen } from './screens/question-finished/QuestionFinishedScreen';
import { LivePollType } from './models/LivePollType';
import { Spinner } from './components/spinner/Spinner';
import React, { useState } from 'react';
import { setTheme } from './screens/utils/themes/setThemes';
import { PoweredBy } from './components/powered-by/PoweredBy';
import { CompanyLogo } from './components/company-logo/CompanyLogo';
import { redirectTo } from './screens/utils/redirectUtil';
import { Team } from './models/Team';
import { SelectTeamScreen } from './screens/waiting-room/components/SelectTeamScreen';
import { addSessionInfo } from './components/util/manageSessionInfo.util';

export const TakeLivePoll = () => {
  const [themeLoaded, setThemeLoaded] = useState<boolean>(false);
  const { loadingSession, serverState } = useTakeLivePollState();
  const { respondent, score } = serverState;
  const theme = serverState.livePollSession?.setting?.theme.setting!;
  const changeRespondentTeam = useChangeRespondentTeam();

  React.useEffect(() => {
    if (!themeLoaded) {
      setTheme(theme);
      setThemeLoaded(true);
    }
  }, [theme, themeLoaded]);

  if (loadingSession) {
    return <Spinner message="Loading session..." />;
  }
  const livePollSession = serverState.livePollSession;
  if (!livePollSession) {
    return (
      <div>
        Oops an error occurred loading app (Unable to load LivePollSession).
      </div>
    );
  }
  const pin = livePollSession.pin;
  const fourDigitPin = getFourDigitPin(pin);
  const sessionInfo = {
    pin: fourDigitPin,
    respondentId: serverState.respondent.uid,
    livePollSessionId: livePollSession.id,
  };

  addSessionInfo(sessionInfo);
  const title = livePollSession.name || '';
  let content = undefined;
  const rightHeaderLink = <ExitLink />;
  const handleChangeRespondentTeam = async (team: Team) => {
    const teamUid = team.id!;
    changeRespondentTeam(teamUid);
  };

  const isTeamsEnabled = !!livePollSession.setting?.teamSetting?.enabled;
  const respondentTeamUid = serverState.respondent.teamUid;
  const showTeamSelectScreen = isTeamsEnabled && !respondentTeamUid;

  if (showTeamSelectScreen) {
    content = (
      <div className={styles.container}>
        <SelectTeamScreen onChangeRespondentTeam={handleChangeRespondentTeam} />
      </div>
    );
  } else {
    if (livePollSession.state === LivePollSessionState.WAITING_ROOM) {
      content = <WaitingRoomScreen title={title} />;
    }
    if (livePollSession.state === LivePollSessionState.QUESTION_STARTED) {
      content = <QuestionStartedScreen livePollSession={livePollSession} />;
    }
    if (livePollSession.state === LivePollSessionState.QUESTION_FINISHED) {
      content = <QuestionFinishedScreen />;
    }
    if (
      livePollSession.state === LivePollSessionState.COMPLETED ||
      livePollSession.state === LivePollSessionState.TERMINATED_USER ||
      livePollSession.state === LivePollSessionState.TERMINATED_SYSTEM
    ) {
      redirectTo(`${respondent.uid}/finish-session`);
    }
  }

  const isQuiz = livePollSession.livePollType === LivePollType.QUIZ;

  if (!content) {
    return <Spinner message="Loading..." />;
  }

  return (
    <div className={styles.container}>
      <Header
        isQuiz={isQuiz}
        score={score}
        respondentName={respondent.respondentName}
        rightLink={rightHeaderLink}
        respondentTeamUid={respondent.teamUid}
      />
      <div className={styles.innerContainer}>{content}</div>
      <div className={styles.companyLogoAndBrand}>
        <CompanyLogo />
        <PoweredBy />
      </div>
    </div>
  );
};

const getFourDigitPin = (pin: number) => {
  if (pin.toString().length === 4) {
    return pin;
  } else {
    let appendString = '0';
    if (pin.toString().length === 1) {
      appendString = '000';
    }
    if (pin.toString().length === 2) {
      appendString = '00';
    }
    const newPin = appendString + pin?.toString();
    return newPin;
  }
};
