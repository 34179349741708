import { forwardRef } from 'react';
import { CardAnswerTextRow } from './CardAnswerTextRow';
import styles from './ImageCard.module.scss';
import { SwipeQuestionOptionSide } from '../../../../../models/SwipeQuestionOptionSide.enum';
import { getFontSizeForSwipeQuestionCard } from '../../../../utils/fontUtil';

interface Props {
  idx: number;
  cardTitle: string;
  cardImageUrl: string;
  cardSwipeDirection: null | SwipeQuestionOptionSide;
  leftText: string;
  rightText: string;
  gradientOverlayRef: React.RefObject<HTMLDivElement> | null;
  cardAnswerTextRowRef: React.RefObject<HTMLDivElement> | null;
  blurClassName?: string;
}

export const ImageCard = forwardRef<HTMLDivElement, Props>(
  (
    {
      idx,
      cardTitle,
      cardImageUrl,
      cardSwipeDirection,
      leftText,
      rightText,
      blurClassName,
      gradientOverlayRef,
      cardAnswerTextRowRef,
    },
    ref,
  ) => {
    return (
      <>
        <img
          src={cardImageUrl}
          alt={`card-${idx}`}
          className={`${styles.cardImgBackground} ${blurClassName || ''}`}
        />
        <CardAnswerTextRow
          idx={idx}
          isImageCard={true}
          cardSwipeDirection={cardSwipeDirection}
          leftText={leftText}
          rightText={rightText}
          cardAnswerTextRowRef={cardAnswerTextRowRef}
        />
        {idx === 0 && (
          <div className={styles.overlayForText}>
            <span
              style={{ fontSize: getFontSizeForSwipeQuestionCard(cardTitle) }}
            >
              {cardTitle}
            </span>
          </div>
        )}
        <div
          className={styles.swipeGradientOverlay}
          ref={gradientOverlayRef}
        ></div>
      </>
    );
  },
);
