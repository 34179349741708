export const SpeedRankIconSvg = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0333 0L14.0347 12H22.0015C22.5056 12 22.9912 12.2145 23.3608 12.6006C24.1699 13.4458 24.2172 14.8694 23.4665 15.7804L8.03912 32L10.0377 20H1.99855C1.49276 20 1.00578 19.7841 0.63581 19.3958C-0.171566 18.5485 -0.215957 17.1248 0.536661 16.2158L16.0333 0Z"
      fill="currentColor"
    />
  </svg>
);
