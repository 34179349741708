interface Props {
  color: string;
}

export const WordCloudSvg = ({ color }: Props) => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.83144 16.3867H5.74345C3.91493 16.3867 2.43262 14.9149 2.43262 13.0993C2.43262 11.532 3.53723 10.2209 5.01562 9.89163C4.95027 9.60165 4.91574 9.29987 4.91574 8.99009C4.91574 6.72063 6.76863 4.88086 9.05428 4.88086C11.0565 4.88086 12.7266 6.2926 13.11 8.16826L15.4191 8.16825C17.7047 8.16825 19.5576 10.008 19.5576 12.2775C19.5576 14.547 17.7047 16.3867 15.4191 16.3867H13.4157H8.83144ZM5.91113 12.6406H16.6143V13.7109H5.91113V12.6406ZM12.333 10.5H5.91113V11.5703H12.333V10.5Z"
        fill={color}
      />
    </svg>
  );
};
